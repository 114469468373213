import * as Yup from 'yup';

const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/;

export const ValidateChangePassword = Yup.object().shape({
  currentPassword: Yup.string().required('Campo obrigatório'),
  newPassword: Yup.string()
    .required('Campo obrigatório')
    .matches(passwordRegex, ' '),
  repeatNewPassword: Yup.string()
    .required('Campo obrigatório')
    .oneOf([Yup.ref('newPassword'), null], 'As senhas precisam ser iguais'),
});

export const ValidatePrivatePerson = () => {
  return Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    otherName: Yup.string().required('Campo obrigatório'),
    phones: Yup.array(
      Yup.object({
        number: Yup.string().test('phoneLen', 'O telefone é inválido', (val) =>
          val ? val?.replace(/\D/g, '')?.length >= 10 : true,
        ),
      }),
    ),
  });
};

export const ValidateLegalPerson = () => {
  return Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    otherName: Yup.string().required('Campo obrigatório'),
    contactDetails: Yup.object({
      emailAdmin: Yup.string()
        .required('Campo obrigatório')
        .email('Digite um E-mail válido'),
    }),
    phones: Yup.array(
      Yup.object({
        number: Yup.string().test('phoneLen', 'O telefone é inválido', (val) =>
          val ? val?.replace(/\D/g, '')?.length >= 10 : true,
        ),
      }),
    ),
  });
};

export const ValidateChangeEmail = Yup.object().shape({
  password: Yup.string().required('Campo obrigatório'),
  newEmail: Yup.string()
    .required('Campo obrigatório')
    .email('Digite um e-mail válido'),
});

export const ValidateConfirmationCode = Yup.object().shape({
  code: Yup.string()
    .required('Campo obrigatório')
    .test('len', 'O código deve possuir 6 dígitos', (val) => val?.length === 6),
});
