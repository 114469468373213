import { Box, CircularProgress, Divider } from '@mui/material';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getEventSponsors } from 'src/services/events';

import SponsorCards from './SponsorCards';

export default function Sponsors({
  mode,
}: {
  mode: 'preview' | 'interaction';
}) {
  const { id } = useParams<{ id: string }>();

  const { data, isLoading } = useQuery(['event-sponsors', id], () =>
    getEventSponsors(id, mode),
  );

  if (isLoading) return <CircularProgress />;

  const groupedData = _.groupBy(data, 'category');

  return (
    <Box padding={'0 28px 28px'}>
      {data?.length > 0 && (
        <>
          <SponsorCards
            data={groupedData.Diamond}
            category="Diamond"
            mode={mode}
          />
          <Divider sx={{ color: '#E0E0E0' }} />
          <SponsorCards
            data={groupedData.Platinum}
            category="Platinum"
            mode={mode}
          />
          <Divider sx={{ color: '#E0E0E0' }} />
          <SponsorCards data={groupedData.Gold} category="Gold" mode={mode} />
          <Divider sx={{ color: '#E0E0E0' }} />
          <SponsorCards
            data={groupedData.Silver}
            category="Silver"
            mode={mode}
          />
          <Divider sx={{ color: '#E0E0E0' }} />
          <SponsorCards
            data={groupedData.Bronze}
            category="Bronze"
            mode={mode}
          />
          <Divider sx={{ color: '#E0E0E0' }} />
          <SponsorCards
            data={groupedData.Copatrocinador}
            category="Copatrocinador"
            mode={mode}
          />
          <Divider sx={{ color: '#E0E0E0' }} />
          <SponsorCards
            data={groupedData.Apoiadores}
            category="Apoiadores"
            mode={mode}
          />
        </>
      )}
    </Box>
  );
}
