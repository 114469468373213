import { useMemo } from 'react';

import { Box, Link, Typography } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';
import moment from 'moment';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { PanelLogin } from 'src/components/PanelLogin';
import SubTitle from 'src/components/v2/SubTitle';
import { useAuthContext } from 'src/context/AuthContextProvider';
import OperationInvestorsTable from 'src/pages/Operation/OperationInvestorsTable';
import { getCriCraInvestorsPositions } from 'src/services/operation';
import { convertNumber, convertValue } from 'src/utils/number';

type Props = {
  operationName: string;
  operationId: string;
};

export default function OperationCRIInvestorsPosition({
  operationName,
  operationId,
}: Props) {
  const { auth } = useAuthContext();

  const columns: MRT_ColumnDef<any>[] = useMemo(
    () => [
      {
        accessorKey: 'investor.name',
        accessorFn: (row) => row.investor,
        header: 'FII Investidor',
        filterFn: (row, id, filterValue) =>
          row?.original?.investor?.name
            ?.toLowerCase()
            ?.includes(filterValue?.toLowerCase()),
        enableColumnFilterModes: false,
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? (
            <Link
              component={RouterLink}
              to={`/operacao/fii/${
                cell.getValue().slug
              }/${cell.getValue().operationId}`}>
              {cell.getValue().name}
            </Link>
          ) : (
            'Ops! Não encontramos\ninvestidores para esta operação'
          ),
      },
      {
        accessorKey: 'quantidade',
        header: 'Quantidade',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? convertNumber(cell.getValue()) : '-',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'valor',
        header: 'Valor de mercado',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? convertValue(cell.getValue()) : '-',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'serieTratada',
        header: 'Série Investida',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? cell.getValue() : '-',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'relatedOperation.class',
        accessorFn: (row) => row.relatedOperation?.class,
        header: 'Classe Investida',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? cell.getValue() : '-',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'dataReferencia',
        header: 'Mês de referência',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) => {
          if (!cell.getValue()) return '-';
          const formattedDate = moment(cell.getValue()).format('MMMM/YYYY');
          return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
        },
        filterFn: 'equals',
        filterVariant: 'date',
        columnFilterModeOptions: [
          'equals',
          'greaterThan',
          'lessThan',
          'between',
        ],
      },
    ],
    [],
  );

  const { data, isLoading } = useQuery(
    ['operation-cri-investors-positions', operationId],
    () =>
      getCriCraInvestorsPositions({
        operationId,
        permission: 'operacoes_cri_posicao_investidores',
      }),
    {
      enabled: !!operationId,
      retry: false,
      useErrorBoundary: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      cacheTime: 0,
      retryOnMount: false,
    },
  );

  const getData = () => (data?.length > 0 ? data : []);

  const extraExportColumns: any = [
    {
      accessorKey: 'operationName',
      header: 'Nome da Operação',
    },
  ];

  return (
    <Box p={4}>
      <SubTitle title={`Posição dos investidores do CRI ${operationName}`} />
      {!auth.isLoggedIn || auth?.user?.subscription?.plan?.type === 'free' ? (
        <PanelLogin small={undefined} message={undefined} />
      ) : (
        <>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '22.1px',
            }}>
            Acompanhe a composição dos investidores em diferentes momentos do
            tempo, veja quem está investindo e em que quantidades. Informações
            disponíveis apenas para investidores que sejam fundos de
            investimentos.
          </Typography>
          <OperationInvestorsTable
            data={getData()}
            columns={columns}
            isLoading={isLoading}
            operationName={operationName}
            market="cri"
            indicator={'posicao'}
            hasBottom={true}
            permission={'operacoes_cri_posicao_investidores'}
            exportPermission={'operacoes_posicao_investidores_exportar'}
            extraExportColumns={extraExportColumns}
            defaultColumnSort="dataReferencia"
          />
        </>
      )}
    </Box>
  );
}
