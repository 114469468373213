import { SyntheticEvent, useEffect, useRef, useState } from 'react';

import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import base64url from 'base64url';
import { format, parseISO } from 'date-fns';
import { ExportToCsv, Options } from 'export-to-csv';
import _ from 'lodash';
import {
  MRT_ColumnDef,
  MRT_ColumnFilterFnsState,
  MRT_ColumnFiltersState,
  MRT_ColumnOrderState,
  MRT_ColumnSizingState,
  MRT_FilterFns,
  MRT_GroupingState,
  MRT_Row,
  MRT_SortingState,
  MRT_TableInstance,
  MRT_TableState,
  MRT_Virtualizer,
  MRT_VisibilityState,
  useMaterialReactTable,
} from 'material-react-table';
import { useQuery } from 'react-query';
import CardDemonstration from 'src/components/CardDemonstration/CardDemonstration';
import { CustomSwitch } from 'src/components/CustomSwitch';
import { CustomTooltip } from 'src/components/CustomTooltip';
import MaterialTable from 'src/components/MaterialTable';
import { MaterialTableDefaultProps } from 'src/components/MaterialTable/MaterialTableDefaultProps';
import ModalButton from 'src/components/OnboardingModal/ModalButton';
import { getPayWallBanner } from 'src/services/informative';

import { useAuthContext } from '../../../context/AuthContextProvider';
import { getAll, getColumns } from '../../../services/operation';
import theme from '../../../theme';
import Export from '../Export';
import SaveFilterModal from '../SaveFilterModal';
import ShareButton from '../Share';
import ToolbarInternal from '../ToolbarInternal';
import { QueryParams } from '../index';
import {
  getColumnSortingId,
  getColumnVisibility,
  getIndicatorByMarket,
} from '../utils';

type Props = {
  state: QueryParams;
  handlePrint: () => void;
  handleSelectView: (p: string) => void;
  setState: (p: any) => void;
  operationInfo: any;
  handleModalOpen: () => void;
};

function AdvancedView({
  state,
  setState,
  operationInfo,
  handlePrint,
  handleSelectView,
  handleModalOpen,
}: Props) {
  const { auth, upgradedPlanByPermission }: any = useAuthContext();
  const hasPermission = Boolean(
    auth?.user?.subscription?.plan?.permissions?.operacoes_visao_avancada,
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);
  const [columns, setColumns] = useState<MRT_ColumnDef<any>[]>([]);
  const [operationsData, setOperationsData] = useState<Record<string, any>[]>(
    [],
  );

  const [grouping, setGrouping] = useState<MRT_GroupingState>([]);
  const [columnFilterFns, setColumnFilterFns] =
    useState<MRT_ColumnFilterFnsState>({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [],
  );
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>([]);
  const [columnSizing, setColumnSizing] = useState<MRT_ColumnSizingState>({});
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    {},
  );

  const [totalCount, setTotalCount] = useState<number>(0);
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);
  const [openSaveFilterModal, setOpenSaveFilterModal] =
    useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<any>();
  const [defaultTableState, setDefaultTableState] = useState<
    Partial<MRT_TableState<any>>
  >({
    grouping: [],
    columnFilterFns: {},
    columnFilters: [],
    sorting: [],
    columnOrder: [],
    columnSizing: {},
    columnVisibility: {},
  });
  const [columnTypes, setColumnTypes] = useState<Record<string, string>>();

  const injectState = (newState: Partial<MRT_TableState<any>>) => {
    const allColumns: string[] = _.keys(columnTypes);

    if (!_.isEmpty(newState?.columnVisibility)) {
      setColumnVisibility((prevState) =>
        allColumns.reduce(
          (acc, cur) =>
            ({
              ...acc,
              [cur]: newState.columnVisibility?.[cur],
            }) as MRT_VisibilityState,
          prevState,
        ),
      );
    }

    if (!_.isEmpty(newState?.columnFilterFns)) {
      setColumnFilterFns((prevState) =>
        _.merge(prevState, _.pick(newState.columnFilterFns, _.keys(prevState))),
      );
    }
    if (
      _.isArray(newState?.columnFilters) &&
      !_.isEmpty(newState?.columnFilters)
    ) {
      const newFilters = newState.columnFilters.filter((f) =>
        allColumns.includes(f.id),
      );
      setColumnFilters(newFilters);
    }
    if (_.isArray(newState?.sorting) && !_.isEmpty(newState?.sorting)) {
      setSorting(newState.sorting.filter((s) => allColumns.includes(s.id)));
    }
    if (_.isArray(newState?.columnOrder) && !_.isEmpty(newState?.columnOrder)) {
      setColumnOrder([
        ...new Set([
          ...newState.columnOrder,
          ...allColumns.filter((c) => !newState.columnOrder?.includes(c)),
        ]),
      ]);
    }
    if (_.isArray(newState?.grouping) && !_.isEmpty(newState?.grouping)) {
      setGrouping(allColumns.filter((c) => newState.grouping?.includes(c)));
    }

    if (
      _.isArray(newState?.columnSizing) &&
      !_.isEmpty(newState?.columnSizing)
    ) {
      setColumnSizing((prevState) =>
        _.merge(prevState, _.pick(newState.columnSizing, _.keys(prevState))),
      );
    }
  };

  const { data: contentPayWallBanner } = useQuery(
    'paywall',
    () => getPayWallBanner('operationAdvancedView'),
    {
      enabled: !hasPermission && !auth.isLoading,
    },
  );

  const { isLoading: isLoadingColumns, refetch: refetchColumns } = useQuery(
    ['operation-columns', state.indicator, state.market, state.mode],
    () =>
      getColumns({
        market: state.market.toLowerCase(),
        indicator: state.indicator.replace(/^(cri|cra|fii|fidc)-(.+)$/, '$2'),
      }),
    {
      enabled: !!state.market && !!state.indicator && hasPermission,
      refetchOnMount: 'always',
      onSuccess: ({ data }: { data: MRT_ColumnDef<any>[] }) => {
        if (_.isEmpty(data)) return;
        setTotalCount(0);
        tableInstance?.reset?.();

        const processedColumns: MRT_ColumnDef<any>[] = data.map(
          (initialColumn): MRT_ColumnDef<any> => {
            switch (initialColumn.meta) {
              case 'boolean':
                return {
                  ...initialColumn,
                  accessorFn: (row) =>
                    Boolean(row[String(initialColumn.accessorKey)]),
                  Cell: ({ cell }) =>
                    cell.getValue<boolean>() ? 'Sim' : 'Não',
                  ...(initialColumn.accessorKey === 'np' && {
                    Cell: ({ cell }) =>
                      cell.getValue<boolean>() ? 'FIDC NP' : 'FIDC',
                    filterSelectOptions: [
                      { text: 'FIDC NP', value: 'true' },
                      { text: 'FIDC', value: 'false' },
                    ],
                  }),
                  ...(initialColumn.accessorKey === 'mm' && {
                    Cell: ({ cell }) =>
                      cell.getValue<boolean>() ? 'FIDC MM' : 'Outros',
                    filterSelectOptions: [
                      { text: 'FIDC MM', value: 'true' },
                      { text: 'Outros', value: 'false' },
                    ],
                  }),
                  muiTableBodyCellProps: {
                    align: 'center',
                  },
                };
              case 'datetime':
              case 'date':
                return {
                  ...initialColumn,
                  muiFilterDatePickerProps: {
                    disableOpenPicker: true,
                  },
                  filterVariant: 'date',
                  accessorFn: (row) => {
                    if (!row[String(initialColumn.accessorKey)]) return null;
                    try {
                      return parseISO(row[String(initialColumn.accessorKey)]);
                    } catch (e) {
                      return null;
                    }
                  },
                  Cell: ({ cell }) =>
                    cell.getValue<Date>()?.toLocaleDateString() || '',
                  muiTableBodyCellProps: {
                    align: 'right',
                  },
                };
              case 'biginteger':
              case 'integer':
                return {
                  ...initialColumn,
                  accessorFn: (row) =>
                    Number(row[String(initialColumn.accessorKey)] ?? 0),
                  muiTableBodyCellProps: {
                    align: 'right',
                  },
                };
              case 'float':
              case 'decimal':
                return {
                  ...initialColumn,
                  accessorFn: (row) =>
                    parseFloat(
                      Number(
                        row[String(initialColumn.accessorKey)] ?? 0,
                      ).toFixed(2),
                    ),
                  Cell: ({ cell }) =>
                    cell.getValue<number>()?.toLocaleString?.('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) || '',
                  muiTableBodyCellProps: {
                    align: 'right',
                  },
                };
              default:
                return {
                  ...initialColumn,
                  accessorFn: (row) =>
                    !row[String(initialColumn.accessorKey)]
                      ? ''
                      : row[String(initialColumn.accessorKey)],
                };
            }
          },
        );

        setProgress(0);

        const order = [
          'mrt-row-expand',
          'mrt-row-numbers',
          ...processedColumns.map((item) => String(item.accessorKey)),
        ];
        setColumnOrder(order);

        const visibility = getColumnVisibility(state.indicator, order);
        setColumnVisibility(visibility);

        const filterFns = data.reduce(
          (obj: any, item: any) => ({
            ...obj,
            [item.accessorKey]: item._filterFn ?? item.filterFn,
          }),
          {},
        );
        setColumnFilterFns(filterFns);

        setDefaultTableState((prevState) =>
          _.merge(prevState, {
            columnOrder: order,
            columnFilterFns: filterFns,
            columnVisibility: visibility,
          }),
        );

        setColumnTypes(
          data.reduce(
            (acc, cur) => ({
              ...acc,
              [String(cur.accessorKey)]: String(cur.meta),
            }),
            {},
          ) || {},
        );

        setColumns(processedColumns);

        setSorting([
          ...sorting,
          { id: getColumnSortingId(state.indicator), desc: true },
        ]);
      },
    },
  );

  const {
    isLoading: isLoadingDetails,
    isError,
    refetch: refetchData,
    isFetched,
  } = useQuery(
    ['operation-details', state.indicator, state.market, state.mode],
    () =>
      getAll(
        {
          market: state.market.toLowerCase(),
          indicator: state.indicator.replace(/^(cri|cra|fii|fidc)-(.+)$/, '$2'),
          cnpj: undefined,
          permission: 'operacoes_visao_avancada',
          mode: 'meta',
        },
        setProgress,
      ),
    {
      enabled: false,
      retry: false,
      useErrorBoundary: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryOnMount: false,
      onSuccess: (data) => {
        setTotalCount(data?.length);
        setOperationsData(data);
      },
    },
  );

  const getUrlToShare = () => {
    const url = new URL(window.location.href);
    const shared = base64url(
      JSON.stringify(
        {
          [state.indicator]: {
            sorting,
            columnFilterFns,
            columnFilters,
            columnSizing,
            columnVisibility,
            columnOrder,
            grouping,
          },
        },
        (k, v) =>
          Array.isArray(v) && v.every((o) => JSON.stringify(o) === '{}')
            ? undefined
            : JSON.stringify(v) === '{}'
              ? undefined
              : v,
      ),
    );
    if (shared) url.searchParams.set('shared', shared);
    return url.href;
  };

  const normalizeFilteredValue =
    (filter: any) =>
    (row: any, id: string, filterValue: string | number, ...args: any) => {
      filterValue = parseValue(id, filterValue);
      return filter(row, id, filterValue, ...args);
    };

  const normalizeFilteredRangeValue =
    (filter: any) =>
    (
      row: any,
      id: string,
      filterValue: [string | number, string | number],
      ...args: any
    ) => {
      const filterArray = filterValue.map((value) => parseValue(id, value));
      return filter(row, id, filterArray, ...args);
    };

  const parseValue = (id: string, value: string | number): string | number => {
    if (!value) return value;
    switch (columnTypes?.[id]) {
      case 'float':
      case 'decimal':
        value = parseFloat(
          value.toString().trim().replaceAll('.', '').replaceAll(',', '.'),
        );
        break;
      case 'date':
      case 'datetime':
        if (/^\d{2}\/\d{2}\/\d{4}$/.test(value.toString().trim())) {
          value = Number(
            value
              .toString()
              .trim()
              .replace(/^(\d{2})\/(\d{2})\/(\d{4})$/, '$3$2$1'),
          );
        }
        break;
    }
    return value;
  };

  const resetTableToDefault = () => {
    setSelectedFilter(null);
    tableInstance.resetColumnFilters();
    tableInstance.resetColumnOrder();
    tableInstance.resetColumnPinning();
    tableInstance.resetColumnSizing();
    tableInstance.resetColumnVisibility();
    tableInstance.resetGlobalFilter();
    tableInstance.resetGrouping();
    tableInstance.resetSorting();
    tableInstance.resetHeaderSizeInfo();
    injectState(defaultTableState);
  };

  const handleSelectFilter = (values: any) => {
    resetTableToDefault();
    setSelectedFilter(values);
    injectState(values?.data);
  };

  const handleTableChange = (values: any, callback: (values: any) => void) => {
    setSelectedFilter(null);
    callback(values);
  };

  const isDisabledSaveFilter = (table: MRT_TableInstance<any>): boolean => {
    return (
      table.getState().isSaving ||
      _.isEqual(
        _.pick(table.getState(), _.keys(defaultTableState)),
        defaultTableState,
      )
    );
  };

  const fakeColumns: MRT_ColumnDef<any>[] = Array(4)
    .fill({})
    .map((_, index) => ({
      accessorKey: `column${index + 1}`,
      header: `Coluna${index + 1}`,
    }));

  const fakeDataRow = fakeColumns.reduce((acc, column, index) => {
    return {
      ...acc,
      [column.accessorKey as string]: `Dado ${index + 1}`,
    };
  }, {});

  const fakeRows = Array(10).fill(fakeDataRow);

  const tableInstance = useMaterialReactTable({
    ...MaterialTableDefaultProps,
    filterFns: {
      ...MRT_FilterFns,
      between: normalizeFilteredRangeValue(MRT_FilterFns.between),
      betweenInclusive: normalizeFilteredRangeValue(
        MRT_FilterFns.betweenInclusive,
      ),
      fuzzy: normalizeFilteredValue(MRT_FilterFns.fuzzy),
      contains: normalizeFilteredValue(MRT_FilterFns.contains),
      // empty: normalizeFilteredValue(MRT_FilterFns.empty),
      endsWith: normalizeFilteredValue(MRT_FilterFns.endsWith),
      equals: normalizeFilteredValue(MRT_FilterFns.equals),
      greaterThan: normalizeFilteredValue(MRT_FilterFns.greaterThan),
      greaterThanOrEqualTo: normalizeFilteredValue(
        MRT_FilterFns.greaterThanOrEqualTo,
      ),
      lessThan: normalizeFilteredValue(MRT_FilterFns.lessThan),
      lessThanOrEqualTo: normalizeFilteredValue(
        MRT_FilterFns.lessThanOrEqualTo,
      ),
      // notEmpty: normalizeFilteredValue(MRT_FilterFns.notEmpty),
      notEquals: normalizeFilteredValue(MRT_FilterFns.notEquals),
      startsWith: normalizeFilteredValue(MRT_FilterFns.startsWith),
    },
    columns: hasPermission ? columns : fakeColumns,
    data: hasPermission ? operationsData : fakeRows,
    defaultColumn: {
      size: 250,
      maxSize: 1000,
    },
    enableTopToolbar: true,
    enableBottomToolbar: hasPermission,
    enableColumnDragging: hasPermission,
    enableColumnOrdering: hasPermission,
    enableColumnResizing: hasPermission,
    enableColumnFilterModes: hasPermission,
    enableGrouping: hasPermission,
    enablePinning: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowVirtualization: true,
    enablePagination: false,
    enableFilterMatchHighlighting: hasPermission,
    enableGlobalFilter: hasPermission,
    enableSorting: hasPermission,
    enableColumnActions: hasPermission,
    enableColumnFilters: hasPermission,
    onSortingChange: (values) => handleTableChange(values, setSorting),
    onColumnOrderChange: (values) => handleTableChange(values, setColumnOrder),
    onColumnSizingChange: (values) =>
      handleTableChange(values, setColumnSizing),
    onColumnVisibilityChange: (values) =>
      handleTableChange(values, setColumnVisibility),
    onColumnFiltersChange: (values) =>
      handleTableChange(values, setColumnFilters),
    onColumnFilterFnsChange: (values) =>
      handleTableChange(values, setColumnFilterFns),
    onGroupingChange: (values) => handleTableChange(values, setGrouping),
    rowCount: totalCount,
    state: {
      grouping,
      columnFilters,
      columnFilterFns,
      sorting,
      columnOrder,
      columnSizing,
      columnVisibility,
      density: 'compact',
      showAlertBanner: isError,
      showColumnFilters: hasPermission,
      showGlobalFilter: true,
      showSkeletons: hasPermission && (isLoadingDetails || !isFetched),
      showProgressBars: false,
      showLoadingOverlay: isLoading,
      isSaving: !!selectedFilter,
    },
    initialState: defaultTableState,
    rowVirtualizerInstanceRef,
    displayColumnDefOptions: {
      'mrt-row-numbers': {
        maxSize: 75,
        muiTableHeadCellProps: (props) => ({
          sx: {
            alignItems: 'center',
            justifyContent: 'center',
          },
        }),
        Header: ({ table }) => (
          <Box display="flex" flexDirection="column" justifyContent="center">
            <IconButton
              onClick={() => setOpenSaveFilterModal(true)}
              disabled={isDisabledSaveFilter(table)}
              sx={{
                '& > svg': {
                  color: '#FF8211',
                },
                '&:disabled': {
                  '& > svg': {
                    color: '#BDBDBD',
                  },
                },
              }}>
              <SaveAltIcon
                sx={{
                  fontSize: '26px',
                }}
              />
            </IconButton>
            <Typography fontSize={12} textAlign="center">
              Salvar
            </Typography>
          </Box>
        ),
      },
    },
    muiTableBodyRowProps: { hover: hasPermission },
    muiTablePaperProps: ({ table }) => ({
      elevation: 0,
      style: {
        zIndex: table.getState().isFullScreen ? 1201 : undefined,
      },
    }),
    muiTableContainerProps: {
      onCopy: (e: SyntheticEvent) => e.preventDefault(),
      sx: (theme) => ({
        maxHeight: '600px',
        border: '1px solid #E0E0E0',
        WebkitFilter: hasPermission ? 'unset' : 'blur(5px)',
        '&::-webkit-scrollbar': {
          height: 10,
          width: 10,
          WebkitAppearance: 'none',
          border: '1px solid #E0E0E0',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 5,
          backgroundColor: theme.palette.grey['800'],
        },
        userSelect: 'none',
        '&::selection, &::-moz-selection': {
          background: 'none',
        },
      }),
    },
    muiTableHeadCellProps: {
      sx: {
        justifyContent: 'center',
        minHeight: '65px',
      },
    },
    muiTopToolbarProps: ({ table }) => ({
      sx: {
        padding: '0 16px',
        backgroundColor: '#FAFAFA',
        boxShadow: '0px 5px 6px 0px rgba(229, 229, 229, 0.50)',
        borderRadius: '3px 3px 0px 0px',
        borderTop: '1px solid #E0E0E0',
        borderRight: '1px solid #E0E0E0',
        borderLeft: '1px solid #E0E0E0',
        '& > .MuiBox-root': {
          flexDirection: 'row-reverse',
          justifyContent: table.getState().isFullScreen
            ? 'flex-end'
            : 'justify-content',
          alignItems: 'center',
          '& .MuiCollapse-root:first-child': {
            display: 'none',
          },
        },
      },
    }),
    renderToolbarInternalActions: ({ table }) => (
      <ToolbarInternal
        table={table}
        market={state.market?.toUpperCase()}
        indicator={state.indicator}
        selectedFilter={selectedFilter}
        handleSelectFilter={handleSelectFilter}
        handleRemoveSelectedFilter={resetTableToDefault}
        type={state.mode}
        disabled={!hasPermission}
      />
    ),
    renderTopToolbarCustomActions: ({ table }) =>
      !table.getState().isFullScreen && (
        <CustomTooltip
          disableFocusListener
          disableTouchListener={
            auth?.user?.subscription?.plan?.permissions?.operacoes_visao_basica
          }
          disableHoverListener={
            auth?.user?.subscription?.plan?.permissions?.operacoes_visao_basica
          }
          arrow
          title={`A visão básica só está disponível para os planos ${upgradedPlanByPermission('operacoes_visao_basica')}`}
          placement="top-start">
          <Box display="flex" alignItems="center">
            <CustomSwitch
              color="secondary"
              checked={state.mode === 'advanced'}
              onChange={(e: any) => {
                handleSelectView(e.target.checked ? 'advanced' : 'basic');
              }}
              inputProps={{ 'aria-label': 'trocar tipo de exibição' }}
              disabled={
                !auth?.user?.subscription?.plan?.permissions
                  ?.operacoes_visao_basica
              }
            />
            <Typography
              variant="caption"
              style={{ color: '#4F4F4F', fontWeight: 600, fontSize: 14 }}>
              Visão avançada
            </Typography>
          </Box>
        </CustomTooltip>
      ),
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Não foi possível obter os dados.',
        }
      : undefined,
    muiLinearProgressProps: {
      value: progress,
      variant: 'determinate',
    },
    // memoMode: 'cells',
    enableRowNumbers: true,
    rowNumberDisplayMode: 'original',
    columnResizeMode: 'onEnd',
    muiBottomToolbarProps: {
      sx: {
        border: '1px solid #E0E0E0',
        borderTop: 'none',
        minHeight: 'unset',
      },
    },
    positionGlobalFilter: 'right',
    muiSearchTextFieldProps: {
      disabled: !hasPermission,
      sx: {
        userSelect: 'none',
        WebkitUserSelect: 'none',
        KhtmlUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        'o-user-select': 'none',
        border: 'none',
        padding: '8px',
        '& .MuiInputBase-root': { background: 'transparent' },
        '& .MuiInputBase-input': {
          '&::placeholder': {
            fontSize: '14px',
            color: '#6D6E71',
            fontWeight: 500,
          },
          '&:focus': {
            borderBottom: '2px solid #FF8211',
          },
          borderBottom: '1px solid #E4E5E9',
          fontSize: '14px',
          padding: '0 4px',
        },
        '& .MuiInputBase-root.MuiInput-root.Mui-disabled:before': {
          borderBottomStyle: 'unset',
        },
        '.MuiSvgIcon-root': {
          color: hasPermission ? '#FF8211' : 'inherit',
          width: '20px',
          height: '20px',
        },
        '.MuiInputAdornment-root': { display: 'none' },
      },
      placeholder: 'Buscar',
      variant: 'standard',
    },
    renderBottomToolbarCustomActions: () =>
      !!operationInfo && (
        <Box
          sx={{
            display: 'flex',
            gap: '1rem',
            p: '4px',
            width: '100%',
            justifyContent: totalCount ? 'space-between' : 'flex-end',
          }}>
          {totalCount > 0 && (
            <Typography
              sx={{
                fontSize: 12,
                color: '#4F4F4F',
              }}>{`Total de ${totalCount} linhas`}</Typography>
          )}
          {operationInfo?.updatedAt && (
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
              {`Atualizado em ${format(
                parseISO(operationInfo?.updatedAt),
                'dd/MM/yyyy',
              )}`}
            </Typography>
          )}
        </Box>
      ),
  });

  useEffect(() => {
    setIsLoading(isLoadingColumns || !columns?.length);
    if (!hasPermission) {
      setIsLoading(false);
    }
  }, [isLoadingColumns, columns]);

  useEffect(() => {
    if (hasPermission) {
      let indicator = state.indicator;

      if (
        !getIndicatorByMarket(state.market)
          .map((i) => i.value)
          .includes(state.indicator)
      ) {
        indicator = getIndicatorByMarket(state.market)[0]?.value as string;
        setState({
          market: state.market,
          mode: state.mode,
          indicator,
          shared: null,
        });
      }

      resetTableToDefault();

      if (
        typeof window !== 'undefined' &&
        getIndicatorByMarket(state.market)
          .map((i) => i.value)
          .includes(state.indicator)
      ) {
        setOperationsData([]);
        void refetchColumns();
        void refetchData();
      }
    }
  }, [state.market, state.indicator, hasPermission]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const shared = state.shared
      ? JSON.parse(base64url.decode(state.shared))
      : undefined;
    if (shared) {
      const newStateFromShared = (shared?.[state.indicator] ||
        {}) as unknown as Partial<MRT_TableState<any>>;
      injectState(newStateFromShared);
      setState({ ...state, shared: null });
    }
  }, [state.shared, isLoading]);

  useEffect(() => {
    if (tableInstance?.getFilteredRowModel().rows.length > 0) {
      _.debounce(() => {
        rowVirtualizerInstanceRef?.current?.scrollToIndex?.(0);
        setTotalCount(tableInstance?.getFilteredRowModel().rows.length || 0);
      }, 500);
    }
  }, [tableInstance?.getFilteredRowModel().rows.length]);

  if (isLoading) {
    return <CircularProgress variant="determinate" value={progress} />;
  }

  const parseValueToCSV = (id: string, value: any): string | number => {
    switch (columnTypes?.[id]) {
      case 'string':
      case 'text':
        value = value || '';
        break;
      case 'float':
      case 'decimal':
        value = value
          ? value?.toLocaleString?.('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : 0;
        break;
      case 'boolean':
        if (id === 'np') {
          value = value ? 'FIDC NP' : 'FIDC';
          break;
        }
        if (id === 'mm') {
          value = value ? 'FIDC MM' : 'Outros';
          break;
        }
        value = value ? 'Sim' : 'Não';
        break;
    }
    return _.isNull(value) ? '' : value;
  };

  const handleExportRows = (rows: MRT_Row<any>[]) => {
    const columnHeaders: { [p: string]: string } = columns.reduce(
      (acc, c) => ({
        ...acc,
        [String(c.accessorKey)]: `"${c.header}"`,
      }),
      {},
    );
    const parsedRows = rows.map((row) =>
      Object.keys(columnHeaders).reduce(
        (acc, key) => ({
          ...acc,
          [key]: parseValueToCSV(key, row.original[key]),
        }),
        {},
      ),
    );

    const csvOptions = {
      fieldSeparator: ';',
      quoteStrings: '"',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      filename: `operations_${state.indicator}_${state.mode}`.toLowerCase(),
      decimalSeparator: ',',
      headers: Object.values(columnHeaders),
    } as Options;

    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(parsedRows);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box>
        <Box
          p={2}
          pr={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between">
          <FormControl
            variant="standard"
            sx={{
              minWidth: 200,
              userSelect: 'none',
              WebkitUserSelect: 'none',
              KhtmlUserSelect: 'none',
              MozUserSelect: 'none',
              msUserSelect: 'none',
              'o-user-select': 'none',
            }}
            size={'small'}
            disabled={!hasPermission}
            aria-disabled={!hasPermission}>
            <InputLabel
              id="indicator-label"
              sx={{ color: theme.palette.secondary.main }}>
              Escolha um indicador
            </InputLabel>
            <Select
              labelId="indicator-label"
              id="indicator-select"
              value={state.indicator}
              onChange={(event: SelectChangeEvent) => {
                setState({ ...state, indicator: event.target.value });
              }}
              autoWidth
              label="Escolha um indicador">
              {getIndicatorByMarket(state.market).map(
                (indicatorItem, index) => (
                  <MenuItem key={index} value={indicatorItem.value}>
                    {indicatorItem.label}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <ModalButton onClick={handleModalOpen} disabled={!hasPermission} />
            <Export
              handleExport={() => {
                if (tableInstance) {
                  handleExportRows(tableInstance.getRowModel().rows);
                }
              }}
              handlePrint={handlePrint}
              permission={'operacoes_exportar'}
              hasData={!!operationsData?.length}
            />
            <ShareButton
              getUrlToShare={getUrlToShare}
              disabled={!hasPermission}
            />
          </Box>
        </Box>
        <MaterialTable table={tableInstance} />
      </Box>
      {!hasPermission && contentPayWallBanner?.operationAdvancedView && (
        <Box
          sx={{
            width: '100%',
            position: 'absolute',
            top: '40%',
            left: 0,
            px: '10px',
          }}>
          <CardDemonstration
            title={contentPayWallBanner?.operationAdvancedView?.title}
            subTitle={contentPayWallBanner?.operationAdvancedView?.subTitle}
            labelButton={
              contentPayWallBanner?.operationAdvancedView?.labelButton
            }
            url={contentPayWallBanner?.operationAdvancedView?.url}
          />
        </Box>
      )}
      <SaveFilterModal
        isOpen={openSaveFilterModal}
        handleClose={() => setOpenSaveFilterModal(false)}
        filterData={{
          type: state.mode,
          indicator: state.indicator,
          market: state.market,
          data: {
            columnOrder,
            columnFilters,
            sorting,
            columnFilterFns,
            columnVisibility,
            grouping,
          },
        }}
      />
    </Box>
  );
}

export default AdvancedView;
