import { useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import Test from 'react-test-attributes';
import RoleSelectField from 'src/components/FormsUI/RoleSelectField';
import { getResponsibilities } from 'src/services/professional';
import { updateUser } from 'src/services/user';
import { User } from 'src/types/user';

import PhoneFormat from '../../../components/FormsUI/PhoneFormat';
import { setBannerMessage } from '../../../store/display';
import * as S from '../styles';

interface ResponsibilityOptions {
  inputValue?: string;
  name: string;
}

type Props = {
  registerType: string;
  data: any;
  handleFinished: () => void;
};

function ComplementaryRegisterForm({
  registerType,
  data,
  handleFinished,
}: Props) {
  const dispatch = useDispatch();
  const [responsibilities, setResponsibilities] = useState<
    ResponsibilityOptions[]
  >([]);

  const mutationUpdate = useMutation(updateUser, {
    onError: (error: any) => {
      if (error) {
        dispatch(
          setBannerMessage({
            message: error?.response?.data?.error?.message,
            type: 'error',
            autoclose: true,
          }),
        );
      }
    },
    onSuccess: async () => {
      handleFinished();
    },
  });

  const { isLoading: responsibilityLoading } = useQuery(
    'responsibilities',
    getResponsibilities,
    {
      onSuccess: (data) => {
        setResponsibilities(data);
      },
    },
  );

  const formik = useFormik({
    initialValues: {
      company: '',
      phone: '',
      companyRole: '',
    },
    onSubmit: (values) => {
      if (!values.company && !values.companyRole && !values.phone) {
        handleFinished();
      } else {
        const { phone, company, ...rest } = values;
        let newData = {};
        const phones = [];
        const phoneType = phone.length <= 14 ? 'landline' : 'mobile';

        phones.push({
          phoneType,
          number: phone,
        });

        if (registerType === 'legal_person') {
          newData = {
            ...rest,
            phones,
            otherName: company,
          };
        } else {
          newData = {
            ...rest,
            phones,
            company,
          };
        }

        const updatedData = { ...newData, id: data.id } as User;

        void mutationUpdate.mutateAsync({ data: updatedData });
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ padding: '130px 0', maxWidth: 450, margin: '0 auto' }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography fontSize={28} fontWeight={700} textAlign="center">
              Dados complementares
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <S.FieldTitle>
              {registerType === 'legal_person' ? 'Nome fantasia' : 'Empresa'}
            </S.FieldTitle>
            <FormControl fullWidth variant="outlined">
              {/* @ts-ignore */}
              <Test id="register-form-companyname">
                <TextField
                  name="company"
                  placeholder="Insira o nome da empresa"
                  value={formik.values.company}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.company && Boolean(formik.errors.company)
                  }
                  helperText={formik.touched.company && formik.errors.company}
                />
              </Test>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <RoleSelectField
              id="register-select-companyRole"
              options={responsibilities}
              loading={responsibilityLoading}
              label={
                registerType === 'legal_person'
                  ? 'Cargo do administrador'
                  : 'Cargo'
              }
              name="companyRole"
              placeholder="Selecione ou digite o cargo"
              value={formik.values.companyRole}
              setFieldValue={formik.setFieldValue}
            />
          </Grid>
          <Grid item xs={12}>
            <S.FieldTitle>Telefone</S.FieldTitle>
            <FormControl fullWidth variant="outlined">
              {/* @ts-ignore */}
              <Test id="register-form-phone">
                <TextField
                  name="phone"
                  placeholder="Insira seu telefone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                  InputProps={{
                    inputComponent: PhoneFormat as any,
                  }}
                />
              </Test>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          alignItems="center"
          justifyContent="center"
          marginTop={9}>
          {/* @ts-ignore */}
          <Test id="register-button-finish">
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit">
              {mutationUpdate.isLoading ? (
                <CircularProgress sx={{ color: '#FFFFFF' }} />
              ) : (
                'Finalizar'
              )}
            </Button>
          </Test>
        </Grid>
      </Box>
    </form>
  );
}

export default ComplementaryRegisterForm;
