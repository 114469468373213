import styled from 'styled-components';

export const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  letter-spacing: 6px;
  font-size: 16px;
  padding-bottom: 10px;
  padding-top: 20px;
`;

export const Wrapper = styled.div`
  width: 100%;

  img {
    max-width: 50%;
  }
`;

type SponsorLogosProps = {
  size: 'big' | 'medium' | 'small' | 'smaller';
  columns: string;
  display: 'grid' | 'flex';
  columngap: string;
};

const ImageDimensions = {
  big: { width: '231px', height: '70px' },
  medium: { width: '181px', height: '45px' },
  small: { width: '140px', height: '40px' },
  smaller: { width: '100px', height: '33px' },
};

export const SponsorLogos = styled.div<SponsorLogosProps>`
  display: ${(props) => props.display};
  grid-template-columns: ${(props) => props.columns};
  grid-auto-rows: 200px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  row-gap: 60px;
  column-gap: ${(props) => props.columngap};
  flex-wrap: wrap;

  img {
    width: ${(props) => ImageDimensions[props.size].width};
    height: ${(props) => ImageDimensions[props.size].height};

    &:hover {
      cursor: pointer;
    }
  }
`;

export const SponsorsWrapper = styled.div`
  padding-top: 145px;
`;
