import { useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import {
  Button,
  CircularProgress,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { CustomTooltip } from 'src/components/CustomTooltip';
import SubscribeToEventDialog from 'src/components/SubscribeToEventDialog';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { subscribeToEvent } from 'src/services/events';
import { setBannerMessage } from 'src/store/display';
import { Event } from 'src/types/event';

import SubscribeToEventParticipantNameDialog from '../../../../components/SubscribeToEventParticipantNameDialog';

type SubscribeButtonProps = {
  event: Event;
  refetch?: () => void;
  variant?: 'contained' | 'text';
  typePosition?: 'header' | 'body';
  acceptedTermsAndConditions?: boolean;
};

export default function SubscribeButton({
  event,
  refetch,
  variant = 'contained',
  typePosition = 'header',
  acceptedTermsAndConditions = false,
}: SubscribeButtonProps) {
  const { auth } = useAuthContext();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const [openSubscribeDialog, setOpenSubscribeDialog] = useState(false);
  const dispatch = useDispatch();

  const getButtonLabel = () => {
    if (event.subscribed) {
      if (!event?.isLive) {
        return (
          <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            Inscrito
            <CheckCircleIcon sx={{ fontSize: '16px' }} />
          </Typography>
        );
      } else {
        return 'Acessar agora';
      }
    } else {
      return 'Inscrever-se';
    }
  };

  const subscribeToEventMutation = useMutation(subscribeToEvent, {
    onSuccess: () => handleSuccess(),
    onError: () => {
      if (variant === 'text') {
        dispatch(
          setBannerMessage({
            message: 'Não foi possível realizar a inscrição.',
            type: 'error',
            autoclose: true,
          }),
        );
      }
    },
  });

  const handleSuccess = () => {
    if (variant === 'text') {
      dispatch(
        setBannerMessage({
          message: 'Inscrição realizada com sucesso.',
          type: 'success',
          autoclose: true,
        }),
      );
    }
    if (refetch) {
      refetch();
    }
  };

  const handleClick = () => {
    if (!auth.isLoggedIn || subscribeToEventMutation.isLoading) return;

    if (!event.isUqbar) {
      window.open(event.subscribeUrl, '_blank', 'noopener noreferrer');
      return;
    }

    if (!event.subscribed) {
      if (event.hasPermission && auth.user?.type === 'private_person') {
        subscribeToEventMutation.mutate({
          id: event.id,
          acceptedTermsAndConditions,
        });
        return;
      }
      setOpenSubscribeDialog(true);
      return;
    }

    if (event.subscribed && event?.isLive && event?.hasPermission) {
      window.open(`/evento/${event.slug}/${event.id}/programacao`, '_blank');
    }
  };

  return (
    <CustomTooltip
      disableFocusListener
      disableTouchListener={auth.isLoggedIn}
      disableHoverListener={auth.isLoggedIn}
      arrow
      title={
        !auth.isLoggedIn ? 'Faça login para acessar esta funcionalidade' : ''
      }
      placement="top"
      followCursor={true}>
      <span style={{ marginLeft: 'auto' }}>
        {variant === 'contained' ? (
          <Button
            disabled={
              !auth.isLoggedIn ||
              (event.termsOfUseLink && !acceptedTermsAndConditions) ||
              event.subscribed
            }
            sx={(theme) => ({
              ...(typePosition === 'header'
                ? {
                    position: 'absolute',
                    top: '24px',
                    right: '24px',
                  }
                : {}),
              display:
                (!event?.subscribed && !event?.isSubscriptionOpen) ||
                (event?.subscribed && event?.state === 'ongoing') ||
                !event.allowSubscriptions
                  ? 'none'
                  : 'normal',
              backgroundColor:
                (event?.subscribed && !event?.isLive) || !auth.isLoggedIn
                  ? '#6D6E71'
                  : '#FF8211',
              color: 'white',
              padding: '16px',
              textTransform: 'none',
              cursor:
                (auth.isLoggedIn &&
                  !event.subscribed &&
                  event?.isSubscriptionOpen) ||
                (event.subscribed && event?.isLive)
                  ? 'pointer'
                  : 'auto',
              '&:hover': {
                backgroundColor:
                  (event?.subscribed && !event?.isLive) || !auth.isLoggedIn
                    ? '#6D6E71'
                    : '#FF8211',
              },
              '& > p': {
                fontSize: '18px',
                fontWeight: 600,
              },
              '&:disabled': {
                color: 'white',
                backgroundColor: '#6D6E71',
              },
              [theme.breakpoints.down('sm')]: {
                ...(typePosition === 'header'
                  ? {
                      top: 'auto',
                      bottom: '24px',
                      right: '24px',
                    }
                  : {}),
                '& > p': {
                  fontSize: '12px',
                  fontWeight: 600,
                },
              },
            })}
            size={isMobile ? 'small' : 'large'}
            disableRipple={!auth.isLoggedIn || event?.subscribed}
            onClick={handleClick}>
            {subscribeToEventMutation.isLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              getButtonLabel()
            )}
          </Button>
        ) : (
          <Button
            startIcon={
              subscribeToEventMutation.isLoading ? undefined : (
                <PlayCircleFilledRoundedIcon color="primary" fontSize="small" />
              )
            }
            color="primary"
            onClick={handleClick}
            disabled={
              !auth.isLoggedIn ||
              (event.termsOfUseLink && !acceptedTermsAndConditions) ||
              event.subscribed
            }
            sx={{
              padding: 0,
              minWidth: 'unset',
              textTransform: 'none',
              textDecoration: 'underline !important',
              textWrap: 'nowrap',
              letterSpacing: 'unset',
              fontSize: { xs: 12, sm: 14 },
              fontWeight: 700,
              display: event.subscribed ? 'none' : 'inline-flex',
            }}>
            {subscribeToEventMutation.isLoading ? (
              <CircularProgress color="inherit" />
            ) : (
              'Inscrever-se'
            )}
          </Button>
        )}
        {event.hasPermission && auth?.user?.type === 'legal_person' ? (
          <SubscribeToEventParticipantNameDialog
            acceptedTermsAndConditions={acceptedTermsAndConditions}
            isOpen={openSubscribeDialog}
            handleClose={() => setOpenSubscribeDialog(false)}
            handleSuccess={handleSuccess}
            event={event}
          />
        ) : (
          <SubscribeToEventDialog
            acceptedTermsAndConditions={acceptedTermsAndConditions}
            isOpen={openSubscribeDialog}
            handleClose={() => setOpenSubscribeDialog(false)}
            handleSuccess={handleSuccess}
            event={event}
          />
        )}
      </span>
    </CustomTooltip>
  );
}
