import { useState } from 'react';

import {
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import lodash from 'lodash';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import Test from 'react-test-attributes';
import ImageInput from 'src/components/ImageInput';
import { createInstitution, updateInstitution } from 'src/services/institution';
import { setBannerMessage } from 'src/store/display';
import { Institution } from 'src/types/institution';
import { Market } from 'src/types/market';
import { Segment } from 'src/types/segments';
import { Media } from 'src/types/strapi';
import { User } from 'src/types/user';
import { formatCNPJ } from 'src/utils/document';

import * as S from '../styles';
import { ValidationInstitutionForm } from '../validation';

type Props = {
  segments: Segment[];
  markets: Market[];
  user?: User | null;
  institution?: Institution | null;
  handleClose: () => void;
};

function InstitutionForm({
  segments,
  markets,
  user,
  institution,
  handleClose,
}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  const dispatch = useDispatch();
  const [image, setImage] = useState<Media | null | undefined>(
    institution?.logo,
  );
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedSegments, setSelectedSegments] = useState<string>();

  const createInstitutionMutation = useMutation(createInstitution, {
    onSuccess: () => {
      handleClose();
      dispatch(
        setBannerMessage({
          message:
            'Perfil institucional criado com sucesso, acesse clicando <redirect path="/perfil-social/institucional">aqui</redirect>',
          type: 'success',
          autoclose: true,
        }),
      );
    },
    onError: () => {
      setBannerMessage({
        message: 'Ocorreu um erro. Tente novamente',
        type: 'error',
        autoclose: true,
      });
    },
  });

  const updateInstitutionMutation = useMutation(updateInstitution, {
    onSuccess: () => {
      handleClose();
      dispatch(
        setBannerMessage({
          message:
            'Perfil institucional criado com sucesso, acesse clicando <redirect path="/perfil-social/institucional">aqui</redirect>',
          type: 'success',
          autoclose: true,
        }),
      );
    },
    onError: () => {
      setBannerMessage({
        message: 'Ocorreu um erro. Tente novamente',
        type: 'error',
        autoclose: true,
      });
    },
  });

  const formik = useFormik({
    initialValues: {
      logo: null,
      razaoSocial: institution?.razaoSocial ?? user?.name,
      fantasyName: institution?.fantasyName ?? user?.otherName,
      markets: institution?.markets?.map(({ id }) => id) ?? [],
      segments: institution?.segments?.map(({ id }) => id) ?? [],
      websiteUrl: institution?.websiteUrl ?? '',
    },
    validationSchema: ValidationInstitutionForm,
    onSubmit: (values) => {
      const formData = new FormData();
      const { logo, ...institutionData } = values;
      if (logo) {
        formData.append('files.logo', logo);
        formData.append(
          'data',
          JSON.stringify({
            ...institutionData,
            cnpj: formatCNPJ(user?.document),
            slug: lodash.kebabCase(
              `${institutionData.razaoSocial} ${user?.id}`,
            ),
            creationDate: new Date(),
            owner: user?.id,
          }),
        );
      } else {
        formData.append(
          'data',
          JSON.stringify({
            ...institutionData,
            cnpj: formatCNPJ(user?.document),
            slug: lodash.kebabCase(
              `${institutionData.razaoSocial} ${user?.id}`,
            ),
            creationDate: new Date(),
            owner: user?.id,
            ...(!image ? { logo: null } : { logo: image.id }),
          }),
        );
      }

      if (institution) {
        updateInstitutionMutation.mutateAsync({
          id: institution.id,
          values: formData,
        });
      } else {
        createInstitutionMutation.mutateAsync(formData);
      }
    },
  });

  const handleTooltip = (status: boolean) => {
    setTooltipOpen(status);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogContent
        sx={{ padding: isMobile ? '10px 20px 10px 30px' : '10px 60px' }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={4}
            container
            justifyContent={isMobile ? 'center' : 'flex-start'}
            alignItems="center">
            <ImageInput
              type="square"
              name="logo"
              image={image}
              setImage={setImage}
              value={formik.values.logo}
              setValue={formik.setFieldValue}
              error={formik.touched.logo && Boolean(formik.errors.logo)}
              helperText={formik.errors.logo}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            container
            justifyContent="center"
            flexDirection="column">
            <Grid item>
              <S.FieldTitle>Razão social*</S.FieldTitle>
              <FormControl fullWidth variant="outlined">
                {/* @ts-ignore */}
                <Test id="institutionalProfile-form-razaoSocial">
                  <TextField
                    name="razaoSocial"
                    placeholder="Digite aqui a razão social"
                    onChange={formik.handleChange}
                    value={formik.values.razaoSocial}
                    error={
                      formik.touched.razaoSocial &&
                      Boolean(formik.errors.razaoSocial)
                    }
                    helperText={
                      formik.touched.razaoSocial && formik.errors.razaoSocial
                    }
                  />
                </Test>
              </FormControl>
            </Grid>
            <Grid item sx={{ paddingTop: '24px' }}>
              <S.FieldTitle>Nome Fantasia*</S.FieldTitle>
              <FormControl fullWidth variant="outlined">
                {/* @ts-ignore */}
                <Test id="institutionalProfile-form-fantasyName">
                  <TextField
                    name="fantasyName"
                    placeholder="Insira o nome da instituição"
                    onChange={formik.handleChange}
                    value={formik.values.fantasyName}
                    error={
                      formik.touched.fantasyName &&
                      Boolean(formik.errors.fantasyName)
                    }
                    helperText={
                      formik.touched.fantasyName && formik.errors.fantasyName
                    }
                  />
                </Test>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <S.FieldTitle>
              Quais segmentos mais se relacionam com a sua área de atuação?*
            </S.FieldTitle>
            <Tooltip
              title={selectedSegments}
              open={tooltipOpen && formik.values.segments.length > 0}
              placement="top">
              <FormControl
                fullWidth
                variant="outlined"
                error={
                  formik.touched.segments && Boolean(formik.errors.segments)
                }
                onMouseEnter={() => {
                  handleTooltip(true);
                }}
                onMouseLeave={() => {
                  handleTooltip(false);
                }}
                onClick={() => {
                  handleTooltip(false);
                }}
                onMouseDown={() => {
                  handleTooltip(false);
                }}>
                {/* @ts-ignore */}
                <Test id="institutionalProfile-select-segments">
                  <Select
                    name="segments"
                    value={formik.values.segments}
                    onChange={formik.handleChange}
                    multiple
                    displayEmpty
                    renderValue={(selected: any) => {
                      if (!selected?.length) {
                        return (
                          <Typography sx={{ color: '#BDBDBD' }}>
                            Selecione o(s) segmento(s)
                          </Typography>
                        );
                      }
                      const selectedSegments: Segment[] = segments.filter(
                        (segment: any) =>
                          selected.some((s: any) => s === segment.id),
                      );
                      setSelectedSegments(
                        selectedSegments.map(({ name }) => name).join(', '),
                      );
                      return selectedSegments
                        .map(({ name }) => name)
                        .join(', ');
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: 250,
                        },
                      },
                      MenuListProps: {
                        sx: {
                          maxHeight: 240,
                          overflowY: 'scroll',
                          '&::-webkit-scrollbar': {
                            height: 10,
                            width: 8,
                            WebkitAppearance: 'none',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            borderRadius: 4,
                            backgroundColor: theme.palette.grey['400'],
                          },
                        },
                      },
                    }}>
                    {segments?.map((opt: Segment) => (
                      <MenuItem value={opt.id} key={opt.id}>
                        <Checkbox
                          checked={formik.values.segments.some(
                            (value) => value === opt.id,
                          )}
                        />
                        <ListItemText primary={opt.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </Test>
                {Boolean(formik.errors.segments) && formik.touched.segments && (
                  <FormHelperText>{formik.errors.segments}</FormHelperText>
                )}
              </FormControl>
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={6}>
            <S.FieldTitle>Mercados*</S.FieldTitle>
            <FormControl
              fullWidth
              variant="outlined"
              error={formik.touched.markets && Boolean(formik.errors.markets)}>
              {/* @ts-ignore */}
              <Test id="institutionalProfile-select-markets">
                <Select
                  name="markets"
                  value={formik.values.markets}
                  onChange={formik.handleChange}
                  multiple
                  displayEmpty
                  renderValue={(selected: any) => {
                    if (!selected?.length) {
                      return (
                        <Typography sx={{ color: '#BDBDBD' }}>
                          Selecione os mercados
                        </Typography>
                      );
                    }
                    const selectedMarkets: Market[] = markets.filter(
                      (market: any) =>
                        selected.some((s: any) => s === market.id),
                    );
                    return selectedMarkets.map(({ name }) => name).join(', ');
                  }}>
                  {markets
                    ?.filter((m: Market) =>
                      ['CRI', 'CRA', 'FII', 'FIDC'].includes(m.name),
                    )
                    .map((opt: Market) => (
                      <MenuItem value={opt.id} key={opt.id}>
                        <Checkbox
                          checked={formik.values.markets.some(
                            (value) => value === opt.id,
                          )}
                        />
                        <ListItemText primary={opt.name} />
                      </MenuItem>
                    ))}
                </Select>
              </Test>
              {Boolean(formik.errors.markets) && formik.touched.markets && (
                <FormHelperText>{formik.errors.markets}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <S.FieldTitle>Link do site</S.FieldTitle>
            <FormControl fullWidth variant="outlined">
              {/* @ts-ignore */}
              <Test id="institutionalProfile-form-websiteUrl">
                <TextField
                  name="websiteUrl"
                  placeholder="Insira aqui o link do site da empresa"
                  onChange={formik.handleChange}
                  value={formik.values.websiteUrl}
                  error={
                    formik.touched.websiteUrl &&
                    Boolean(formik.errors.websiteUrl)
                  }
                  helperText={
                    formik.touched.websiteUrl && formik.errors.websiteUrl
                  }
                />
              </Test>
            </FormControl>
          </Grid>
        </Grid>
        <Typography
          sx={{
            color: '#4F4F4F',
            fontSize: '12px',
            marginTop: '30px',
            textAlign: 'center',
          }}>
          Caso queira completar seu perfil faça login, clique no avatar e
          selecione a opção “perfil social”.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', padding: '18px 0' }}>
        {/* @ts-ignore */}
        <Test id="institutionalProfile-button-submit">
          <Button variant="contained" size="large" type="submit">
            {createInstitutionMutation.isLoading ||
            updateInstitutionMutation.isLoading ? (
              <CircularProgress sx={{ color: '#FFFFFF' }} />
            ) : (
              'Salvar'
            )}
          </Button>
        </Test>
      </DialogActions>
    </form>
  );
}

export default InstitutionForm;
