import { useMemo } from 'react';

import { useMediaQuery } from '@material-ui/core';
import {
  AccountBalance,
  Assignment,
  EventNote,
  Help,
  QueryBuilder,
} from '@mui/icons-material';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { getEvent } from 'src/services/events';
import { v4 as uuid } from 'uuid';

import FaqSection from './FaqSection';
import MainPage from './MainPage';
import Schedule from './Schedule';
import Speakers from './Speakers';
import Sponsors from './Sponsors';
import * as S from './styles';

const TABS = {
  visaoGeral: {
    key: 0,
    name: 'visao-geral',
    icon: <EventNote />,
    label: 'Visão geral',
  },
  patrocinadores: {
    key: 1,
    name: 'patrocinadores',
    icon: <AccountBalance />,
    label: 'Patrocinadores',
  },
  palestrantes: {
    key: 2,
    name: 'palestrantes',
    icon: <Assignment />,
    label: 'Palestrantes',
  },
  programacao: {
    key: 3,
    name: 'programacao',
    icon: <QueryBuilder />,
    label: 'Programação',
  },
  faq: {
    key: 4,
    name: 'faq',
    icon: <Help />,
    label: 'FAQ',
  },
};

export const useEventTabManager = () => {
  const { id, tab, slug } = useParams<{
    id: string;
    slug: string;
    tab?: string;
  }>();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
  const history = useHistory();

  const { data, isLoading, refetch } = useQuery(
    ['event', id],
    () => getEvent(id),
    {
      enabled: !!id,
    },
  );

  const hasSpeakers =
    data?.presentations?.length &&
    data?.presentations?.some(
      (presentation: any) => presentation?.speakers?.length > 0,
    );
  const tabData = [
    {
      name: TABS.visaoGeral.name,
      content: (
        <MainPage key={TABS.visaoGeral.key} data={data} refetch={refetch} />
      ),
      tab: (
        <S.Tab
          key={uuid()}
          icon={TABS.visaoGeral.icon}
          iconPosition="start"
          label={TABS.visaoGeral.label}
          sx={{
            display: 'block',
          }}
        />
      ),
      tabMobile: {
        label: TABS.visaoGeral.label,
        icon: TABS.visaoGeral.icon,
        shouldShow: true,
        key: TABS.visaoGeral.key,
      },
    },
    {
      name: TABS.patrocinadores.name,
      content: <Sponsors key={TABS.patrocinadores.key} mode="interaction" />,
      tab: (
        <S.Tab
          key={uuid()}
          icon={TABS.patrocinadores.icon}
          iconPosition="start"
          label={TABS.patrocinadores.label}
          sx={{
            display: data?.sponsors?.length ? 'block' : 'none',
          }}
        />
      ),
      tabMobile: {
        label: TABS.patrocinadores.label,
        icon: TABS.patrocinadores.icon,
        shouldShow: data?.sponsors?.length,
        key: TABS.patrocinadores.key,
      },
    },
    {
      name: TABS.palestrantes.name,
      content: <Speakers key={TABS.palestrantes.key} />,
      tab: (
        <S.Tab
          key={uuid()}
          icon={TABS.palestrantes.icon}
          iconPosition="start"
          label={TABS.palestrantes.label}
          sx={{
            display: hasSpeakers ? 'block' : 'none',
          }}
        />
      ),
      tabMobile: {
        label: TABS.palestrantes.label,
        icon: TABS.palestrantes.icon,
        shouldShow: hasSpeakers,
        key: TABS.palestrantes.key,
      },
    },
    {
      name: TABS.programacao.name,
      content: (
        <Schedule key={TABS.programacao.key} event={data} refetch={refetch} />
      ),
      tab: (
        <S.Tab
          key={uuid()}
          icon={TABS.programacao.icon}
          iconPosition="start"
          label={TABS.programacao.label}
          sx={{
            display: data?.presentations?.length ? 'block' : 'none',
          }}
        />
      ),
      tabMobile: {
        label: TABS.programacao.label,
        icon: TABS.programacao.icon,
        shouldShow: data?.presentations?.length,
        key: TABS.programacao.key,
      },
    },
  ];

  if (data?.faq) {
    tabData.push({
      name: TABS.faq.name,
      content: <FaqSection key={TABS.faq.key} event={data} />,
      tab: (
        <S.Tab
          key={uuid()}
          icon={TABS.faq.icon}
          iconPosition="start"
          label={TABS.faq.label}
          sx={{
            display: 'block',
          }}
        />
      ),
      tabMobile: {
        label: TABS.faq.label,
        icon: TABS.faq.icon,
        shouldShow: true,
        key: TABS.faq.key,
      },
    });
  }

  const tabIndex = useMemo(() => {
    if (tab) {
      const index = tabData.findIndex((i) => i.name === tab);
      if (index >= 0) {
        return index;
      }
    }
    return 0;
  }, [tab]);

  const handleChange = (tab: number) => {
    const tabName = tabData[tab]?.name;
    if (tabName) history.push(`/evento/${slug}/${id}/${tabName}`);
  };

  return {
    tabData,
    data,
    tabIndex,
    handleChange,
    isLoading,
    isMobile,
    refetch,
  };
};
