import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { CustomTooltip } from 'src/components/CustomTooltip';
import { FieldTitle } from 'src/pages/AllEvents/CreateEventModal/styles';
import { subscribeToEvent } from 'src/services/events';
import { Event } from 'src/types/event';
import * as Yup from 'yup';

export default function SubscribeToEventParticipantNameDialog({
  isOpen,
  handleClose,
  handleSuccess,
  event,
  acceptedTermsAndConditions,
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleSuccess: () => void;
  event: Event;
  acceptedTermsAndConditions?: boolean;
}) {
  const subscribeToEventMutation = useMutation(subscribeToEvent, {
    onSuccess: () => {
      handleSuccess();
      onClose();
    },
  });

  const onClose = () => {
    formik.resetForm();
    handleClose();
  };

  const ValidateForm = Yup.object().shape({
    participantName: Yup.string().required('Campo obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      participantName: '',
    },
    validationSchema: ValidateForm,
    validateOnBlur: true,
    onSubmit: (values) => {
      subscribeToEventMutation.mutate({
        id: event.id,
        participantName: values.participantName,
        acceptedTermsAndConditions,
      });
    },
  });

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton
          aria-label="fechar"
          color="inherit"
          size="small"
          onClick={onClose}>
          <Close fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({
          padding: '16px 74px',
          [theme.breakpoints.down('sm')]: {
            padding: '16px 24px',
          },
        })}>
        <form onSubmit={formik.handleSubmit}>
          <Typography fontWeight={700} fontSize={18}>
            Informe seu nome para confirmar sua inscrição
          </Typography>
          <Grid
            container
            sx={(theme) => ({
              padding: '32px 16px',
              [theme.breakpoints.down('md')]: {
                rowGap: 4,
              },
              [theme.breakpoints.down('sm')]: {
                padding: '32px 0',
                rowGap: 4,
              },
            })}>
            <Box width={'100%'}>
              <FieldTitle>Nome completo</FieldTitle>
              <FormControl fullWidth variant="outlined">
                <TextField
                  name="participantName"
                  placeholder="Informe o nome de quem vai comparecer ao evento"
                  onChange={(event) => {
                    formik.handleChange(event);
                  }}
                  inputProps={{ maxLength: 50 }}
                  value={formik.values.participantName}
                  error={Boolean(formik.errors.participantName)}
                  helperText={formik.errors.participantName}
                />
              </FormControl>
            </Box>
            <Box
              width={'100%'}
              display={'flex'}
              justifyContent={'space-around'}
              mt={4}>
              <Button
                variant="outlined"
                type="reset"
                color={'inherit'}
                onClick={onClose}>
                {subscribeToEventMutation.isLoading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  'Cancelar'
                )}
              </Button>
              <CustomTooltip
                title="Informe o nome de quem vai comparecer ao evento"
                disableHoverListener={formik.isValid}
                disableTouchListener={formik.isValid}>
                <span>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!formik.isValid}
                    sx={{
                      '&:disabled': {
                        backgroundColor: '#6D6E71',
                        color: 'white',
                      },
                    }}>
                    {subscribeToEventMutation.isLoading ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      'Inscrever-se'
                    )}
                  </Button>
                </span>
              </CustomTooltip>
            </Box>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
