import {
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AliceCarousel from 'react-alice-carousel';
import SpeakerCard from 'src/pages/Event/Speakers/Speaker/SpeakerCard';
import { Speaker as SpeakerType } from 'src/types/event';

export default function SpeakersDialog({
  speakers,
  open,
  handleClose,
}: {
  speakers: SpeakerType[];
  open: boolean;
  handleClose: () => void;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const renderPrevButton = () => {
    return (
      <IconButton
        sx={(theme) => ({
          position: 'absolute',
          top: '-50px',
          left: isMobile ? -34 : -24,
          transform: 'translate(0, -50%)',
          color: '#828282',
          backgroundColor: 'transparent',
          height: 'fit-content',
          '&:hover': {
            color: '#FF8211',
            opacity: 1,
            backgroundColor: 'transparent',
          },
          fontSize: '1.5rem',
          [theme.breakpoints.down('sm')]: {
            fontSize: '1.125rem',
          },
        })}
        size={isMobile ? 'small' : 'medium'}
        disableRipple>
        <KeyboardArrowLeft sx={{ fontSize: '42px' }} />
      </IconButton>
    );
  };

  const renderNextButton = () => {
    return (
      <IconButton
        sx={{
          position: 'absolute',
          top: '-50px',
          right: isMobile ? -10 : 0,
          transform: 'translate(0, -50%)',
          color: '#828282',
          backgroundColor: 'transparent',
          height: 'fit-content',
          '&:hover': {
            color: '#FF8211',
            opacity: 1,
            backgroundColor: 'transparent',
          },
        }}
        size={isMobile ? 'small' : 'medium'}
        disableRipple>
        <KeyboardArrowRight sx={{ fontSize: '42px' }} />
      </IconButton>
    );
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '5px',
          maxHeight: 'none',
          maxWidth: '512px',
          height: '600px',
          '&::-webkit-scrollbar': {
            height: 10,
            width: 8,
            WebkitAppearance: 'none',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: 4,
            backgroundColor: theme.palette.grey['400'],
          },
        },
      }}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton
          aria-label="fechar"
          color="inherit"
          size="small"
          onClick={handleClose}>
          <Close fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          '&::-webkit-scrollbar': {
            height: 10,
            width: 8,
            WebkitAppearance: 'none',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: 4,
            backgroundColor: theme.palette.grey['400'],
          },
        }}>
        <Typography fontSize={18} fontWeight={700}>
          Resumo dos palestrantes
        </Typography>
        <Box
          sx={{
            width: '100%',
            '& .alice-carousel__prev-btn': {
              position: 'absolute',
              top: '50%',
              padding: 0,
            },
            '& .alice-carousel__next-btn': {
              position: 'absolute',
              top: '50%',
              right: 0,
              padding: 0,
            },
            '& .alice-carousel__dots-item': {
              height: '10px',
              width: '10px',
              backgroundColor: '#E0E0E0',
            },
            '& .alice-carousel__dots-item.__active': {
              height: '10px',
              width: '10px',
              backgroundColor: '#333333',
            },
          }}>
          <AliceCarousel
            items={speakers?.map((speaker) => (
              <Box
                key={`${speaker.id}-speaker`}
                sx={{
                  width: '100%',
                  padding: isMobile ? 0 : '0 32px',
                  '& .MuiPaper-root': {
                    border: 'none',
                    transition: 'none',
                    '&:after': {
                      display: 'none',
                    },
                    '&:hover': {
                      padding: 0,
                    },
                  },
                  '& .MuiCardMedia-root': {
                    display: 'none',
                  },
                  '& .MuiCardHeader-root, .MuiCardContent-root': {
                    display: 'flex',
                  },
                }}>
                <SpeakerCard data={speaker} />
              </Box>
            ))}
            autoPlay={speakers?.length > 1}
            autoPlayInterval={5000}
            autoPlayStrategy="none"
            autoWidth
            infinite={speakers?.length > 1}
            renderPrevButton={renderPrevButton}
            renderNextButton={renderNextButton}
            disableDotsControls={speakers?.length === 1}
            disableButtonsControls={speakers?.length === 1}
            swipeExtraPadding={400}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
