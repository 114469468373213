import { Box, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { EventSponsor } from 'src/types/event';
import { getSmallestImageUrl } from 'src/utils/image';

const mapLogoSizeByCategory: any = {
  Diamond: '160px',
  Platinum: '130px',
  Gold: '110px',
  Silver: '100px',
  Bronze: '90px',
  Copatrocinador: '90px',
  Apoiadores: '90px',
};

export default function SponsorCards({
  data,
  category,
  mode,
}: {
  data: EventSponsor[];
  category: string;
  mode: 'preview' | 'interaction';
}) {
  const history = useHistory();
  if (!data) return null;

  const handleClick = (sponsor: EventSponsor) => {
    if (mode === 'preview') return;
    if (sponsor.link) {
      window.open(sponsor.link, '_blank', 'noopener noreferrer');
    } else {
      history.push(
        `/instituicao/${sponsor?.institution?.slug}/${sponsor?.institution?.id}`,
      );
    }
  };

  return (
    <Box sx={{ margin: '27px 0' }}>
      <Typography
        sx={{
          fontSize: '14px',
          color: '#828282',
        }}>
        {category}
      </Typography>
      <Grid
        container
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '16px',
        }}>
        {data?.map((sponsor: EventSponsor) => (
          <Grid
            container
            item
            key={`sponsor-${sponsor.id}`}
            onClick={() => handleClick(sponsor)}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              width: 'fit-content',
              padding: '45px',
              borderRadius: '10px',
              '&:hover': {
                boxShadow:
                  mode === 'interaction'
                    ? '0px 4px 4px 0px rgba(0, 0, 0, 0.12)'
                    : 'none',
                border:
                  mode === 'interaction'
                    ? '1px solid var(--Gray-5, #E0E0E0)'
                    : 'none',
                cursor: mode === 'interaction' ? 'pointer' : 'normal',
              },
            }}>
            <img
              src={getSmallestImageUrl(sponsor.logo)}
              width={mapLogoSizeByCategory[category]}
              draggable={false}
              style={{
                objectFit: 'contain',
              }}
              alt={sponsor.institution?.fantasyName}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
