import { useState } from 'react';

import { InfoRounded } from '@mui/icons-material';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FormikProps } from 'formik';
import { useQuery } from 'react-query';
import { CustomTooltip } from 'src/components/CustomTooltip';
import CNPJFormat from 'src/components/FormsUI/CNPJFormat';
import { TextAreaProfile } from 'src/components/FormsUI/TextAreaProfile';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getMarkets, getSegments } from 'src/services/institution';
import { Institution, InstitutionInput } from 'src/types/institution';
import { Market } from 'src/types/market';
import { Segment } from 'src/types/segments';

import { FieldTitle } from '../style';

type Props = {
  institution: Institution | undefined;
  formik: FormikProps<InstitutionInput>;
  isRefusedSolicitation: boolean;
  participators: string[] | undefined;
};

function BasicDataForm({
  formik,
  institution,
  isRefusedSolicitation,
  participators,
}: Props) {
  const { auth } = useAuthContext();
  const [segments, setSegment] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [participatorsTooltipOpen, setParticipatorsTooltipOpen] =
    useState(false);
  const [selectedSegments, setSelectedSegments] = useState<string | undefined>(
    institution?.segments?.join(', '),
  );

  const { isLoading: segmentsLoading } = useQuery('segments', getSegments, {
    onSuccess: ({ data }) => {
      setSegment(data);
    },
  });

  const { isLoading: marketsLoading } = useQuery('markets', getMarkets, {
    onSuccess: ({ data }) => {
      setMarkets(
        data.filter((market: any) =>
          ['CRA', 'CRI', 'FII', 'FIDC'].includes(market.name),
        ),
      );
    },
  });

  const handleTooltip = (status: boolean, key: string) => {
    switch (key) {
      case 'segment':
        setTooltipOpen(status);
        break;
      case 'participator':
        setParticipatorsTooltipOpen(status);
        break;
      default:
        break;
    }
  };

  return (
    <Grid container spacing={2} sx={(theme) => ({ padding: theme.spacing(2) })}>
      <Grid
        item
        xs={12}
        md={6}
        container
        justifyContent="center"
        flexDirection="column">
        <FieldTitle>CNPJ*</FieldTitle>
        <FormControl fullWidth variant="outlined">
          <TextField
            name="cnpj"
            onChange={formik.handleChange}
            placeholder="Insira o CNPJ da sua instituição"
            value={formik.values.cnpj}
            disabled={!!formik.values.cnpj && !isRefusedSolicitation}
            InputProps={{
              inputComponent: CNPJFormat,
            }}
            error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
            helperText={formik.touched.cnpj && formik.errors.cnpj}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        container
        justifyContent="center"
        flexDirection="column">
        <FieldTitle>Nome fantasia*</FieldTitle>
        <FormControl fullWidth variant="outlined">
          <TextField
            name="fantasyName"
            placeholder="Insira o nome fantasia da sua instituição"
            onChange={formik.handleChange}
            value={formik.values.fantasyName}
            error={
              formik.touched.fantasyName && Boolean(formik.errors.fantasyName)
            }
            helperText={formik.touched.fantasyName && formik.errors.fantasyName}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldTitle>Segmentos*</FieldTitle>
        <CustomTooltip
          title={selectedSegments}
          open={tooltipOpen && formik.values.segments.length > 0}
          placement="top">
          <FormControl
            fullWidth
            variant="outlined"
            error={formik.touched.segments && Boolean(formik.errors.segments)}
            onMouseEnter={() => {
              handleTooltip(true, 'segment');
            }}
            onMouseLeave={() => {
              handleTooltip(false, 'segment');
            }}
            onClick={() => {
              handleTooltip(false, 'segment');
            }}
            onMouseDown={() => {
              handleTooltip(false, 'segment');
            }}>
            <Select
              name="segments"
              value={formik.values.segments}
              onChange={formik.handleChange}
              multiple
              disabled={segmentsLoading}
              displayEmpty
              renderValue={(selected: any) => {
                if (!selected?.length) {
                  return (
                    <Typography sx={{ color: '#BDBDBD' }}>
                      Selecione o segmento
                    </Typography>
                  );
                }
                const selectedSegments: Segment[] = segments.filter(
                  (segment: any) => selected.some((s: any) => s === segment.id),
                );
                setSelectedSegments(
                  selectedSegments.map(({ name }) => name).join(', '),
                );
                return selectedSegments.map(({ name }) => name).join(', ');
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: 250,
                  },
                },
                MenuListProps: {
                  sx: (theme) => ({
                    maxHeight: 240,
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      height: 10,
                      width: 8,
                      WebkitAppearance: 'none',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      borderRadius: 4,
                      backgroundColor: theme.palette.grey['400'],
                    },
                  }),
                },
              }}>
              {segments?.map((opt: Segment) => (
                <MenuItem value={opt.id} key={opt.id}>
                  <Checkbox
                    checked={formik.values.segments.some(
                      (value) => value === opt.id,
                    )}
                  />
                  <ListItemText primary={opt.name} />
                </MenuItem>
              ))}
            </Select>
            {Boolean(formik.errors.segments) && formik.touched.segments && (
              <FormHelperText>{formik.errors.segments}</FormHelperText>
            )}
          </FormControl>
        </CustomTooltip>
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldTitle>Mercados*</FieldTitle>
        <FormControl
          fullWidth
          variant="outlined"
          error={formik.touched.markets && Boolean(formik.errors.markets)}>
          <Select
            name="markets"
            value={formik.values.markets}
            onChange={formik.handleChange}
            multiple
            disabled={marketsLoading}
            displayEmpty
            renderValue={(selected: any) => {
              if (!selected?.length) {
                return (
                  <Typography sx={{ color: '#BDBDBD' }}>
                    Selecione o mercado
                  </Typography>
                );
              }
              const selectedMarkets: Market[] = markets.filter((market: any) =>
                selected.some((s: any) => s === market.id),
              );
              return selectedMarkets.map(({ name }) => name).join(', ');
            }}>
            {markets
              ?.filter((m: Market) =>
                ['CRI', 'CRA', 'FII', 'FIDC'].includes(m.name),
              )
              .map((opt: Market) => (
                <MenuItem value={opt.id} key={opt.id}>
                  <Checkbox
                    checked={formik.values.markets.some(
                      (value) => value === opt.id,
                    )}
                  />
                  <ListItemText primary={opt.name} />
                </MenuItem>
              ))}
          </Select>
          {Boolean(formik.errors.markets) && formik.touched.markets && (
            <FormHelperText>{formik.errors.markets}</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FieldTitle>
          Título institucional
          <CustomTooltip
            title="Ex: “Somos excelentes em gestão de investimentos”"
            arrow
            placement="top">
            <InfoRounded sx={{ marginLeft: '4px' }} fontSize="small" />
          </CustomTooltip>
        </FieldTitle>
        <FormControl fullWidth variant="outlined">
          <CustomTooltip
            title="Funcionalidade não disponível, faça upgrade."
            disableFocusListener
            disableHoverListener={
              auth.user?.subscription?.plan?.permissions?.instituicao_titulo
            }
            disableTouchListener={
              auth.user?.subscription?.plan?.permissions?.instituicao_titulo
            }
            placement="top"
            arrow={true}>
            <TextField
              name="institutionTitle"
              placeholder="Insira um título de até 70 caracteres sobre a instituição"
              onChange={formik.handleChange}
              disabled={
                !auth.user?.subscription?.plan?.permissions?.instituicao_titulo
              }
              inputProps={{
                maxLength: 70,
              }}
              value={formik.values.institutionTitle}
              error={
                formik.touched.institutionTitle &&
                Boolean(formik.errors.institutionTitle)
              }
              helperText={
                formik.touched.institutionTitle &&
                formik.errors.institutionTitle
              }
            />
          </CustomTooltip>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldTitle>Link do site</FieldTitle>
        <FormControl fullWidth variant="outlined">
          <TextField
            name="websiteUrl"
            placeholder="Insira aqui o link do site da empresa"
            onChange={formik.handleChange}
            value={formik.values.websiteUrl}
            error={
              formik.touched.websiteUrl && Boolean(formik.errors.websiteUrl)
            }
            helperText={formik.touched.websiteUrl && formik.errors.websiteUrl}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldTitle style={{ opacity: 0.25 }}>Área de atuação</FieldTitle>
        <Tooltip
          title={participators}
          open={participatorsTooltipOpen && !!participators?.length}
          placement="top">
          <FormControl
            fullWidth
            variant="outlined"
            error={
              formik.touched.participators &&
              Boolean(formik.errors.participators)
            }
            onMouseEnter={() => {
              handleTooltip(true, 'participator');
            }}
            onMouseLeave={() => {
              handleTooltip(false, 'participator');
            }}
            onClick={() => {
              handleTooltip(false, 'participator');
            }}
            onMouseDown={() => {
              handleTooltip(false, 'participator');
            }}>
            <TextField
              name="participators"
              placeholder="Atribuição automática pela Uqbar"
              disabled
              value={formik.values.participators}
            />
          </FormControl>
        </Tooltip>
      </Grid>
      <TextAreaProfile
        name="businessProfile"
        label="Sobre"
        maxCaracters={2660}
        value={formik.values.businessProfile}
        handleChange={formik.handleChange}
        placeholder="Insira uma descrição detalhada sobre sua empresa, lembre-se que esta descrição será exibida publicamente no perfil institucional"
        variant="outlined"
        fontSize="16px"
      />
    </Grid>
  );
}

export default BasicDataForm;
