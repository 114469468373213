import { useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';
import moment from 'moment';
import { useQuery } from 'react-query';
import { PanelLogin } from 'src/components/PanelLogin';
import SubTitle from 'src/components/v2/SubTitle';
import { useAuthContext } from 'src/context/AuthContextProvider';
import OperationInvestorsTable from 'src/pages/Operation/OperationInvestorsTable';
import { getAll } from 'src/services/operation';
import { convertNumber, convertValue } from 'src/utils/number';

type Props = {
  operationCnpj: string;
  operationName: string;
};

export default function OperationFIIInvestorsPosition({
  operationCnpj,
  operationName,
}: Props) {
  const { auth } = useAuthContext();
  const market = 'fii';
  const indicator = 'posicao-dos-investidores';

  const columns: MRT_ColumnDef<any>[] = useMemo(
    () => [
      {
        accessorKey: 'investidor',
        header: 'Investidor',
        filterFn: 'contains',
        columnFilterModeOptions: [
          'equals',
          'startsWith',
          'endsWith',
          'contains',
        ],
      },
      {
        accessorKey: 'cnpjFundo',
        header: 'CNPJ do investidor',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? cell.getValue() : '-',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'quantidadePosicaoFinal',
        accessorFn: (row: any) => row.quantidadePosicaoFinal,
        header: 'Quantidade',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? convertNumber(cell.getValue()) : '-',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'valorMercadoPosicaoFinal',
        accessorFn: (row: any) => row.valorMercadoPosicaoFinal,
        header: 'Valor de mercado',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? convertValue(cell.getValue()) : '-',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'dataCompetencia',
        accessorFn: (row: any) => row.dataCompetencia,
        header: 'Mês de referência',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: { cell: any }) => {
          if (!cell.getValue()) return '-';
          const formattedDate = moment(cell.getValue()).format('MMMM/YYYY');
          return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
        },
        filterFn: 'equals',
        filterVariant: 'date',
        columnFilterModeOptions: [
          'equals',
          'greaterThan',
          'lessThan',
          'between',
        ],
      },
    ],
    [],
  );

  const { data: investorsData, isLoading: isLoadingDetails } = useQuery(
    ['operation-investors-details', indicator, market, operationCnpj],
    () =>
      getAll({
        market: market,
        indicator: indicator,
        cnpj: operationCnpj,
        permission: 'operacoes_fii_posicao_investidores',
        mode: 'meta',
      }),
    {
      enabled: !!operationCnpj,
      retry: false,
      useErrorBoundary: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      cacheTime: 0,
      retryOnMount: false,
    },
  );

  const getData = () => (investorsData?.length > 0 ? investorsData : []);

  const extraExportColumns = [
    {
      accessorKey: 'cnpjFundoCota',
      header: 'CNPJ da Operação',
    },
    {
      accessorKey: 'operationName',
      header: 'Nome da Operação',
    },
  ];

  return (
    <Box
      p={4}
      sx={{
        '@media print': {
          zoom: '60%',
        },
      }}>
      <SubTitle
        title={`Posição dos investidores do ${market.toUpperCase()} ${operationName}`}
      />
      {!auth.isLoggedIn || auth?.user?.subscription?.plan?.type === 'free' ? (
        <PanelLogin small={undefined} message={undefined} />
      ) : (
        <>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '22.1px',
            }}>
            Acompanhe a composição dos investidores em diferentes momentos do
            tempo, veja quem está investindo e em que quantidades. Informações
            disponíveis apenas para investidores que sejam fundos de
            investimentos.
          </Typography>
          <OperationInvestorsTable
            data={getData()}
            columns={columns}
            isLoading={isLoadingDetails}
            operationName={operationName}
            market={market}
            indicator={indicator}
            hasBottom={true}
            permission={'operacoes_fii_posicao_investidores'}
            exportPermission={'operacoes_posicao_investidores_exportar'}
            extraExportColumns={extraExportColumns}
          />
        </>
      )}
    </Box>
  );
}
