import { ChangeEvent, useCallback, useState } from 'react';

import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  debounce,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { CustomTooltip } from 'src/components/CustomTooltip';
import { FieldTitle } from 'src/pages/AllEvents/CreateEventModal/styles';
import { subscribeToEvent, validateEventInviteCode } from 'src/services/events';
import { Event } from 'src/types/event';
import { buildNamesInline } from 'src/utils/string';
import * as Yup from 'yup';

import { useAuthContext } from '../../context/AuthContextProvider';

export default function SubscribeToEventDialog({
  isOpen,
  handleClose,
  handleSuccess,
  event,
  acceptedTermsAndConditions,
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleSuccess: () => void;
  event: Event;
  acceptedTermsAndConditions?: boolean;
}) {
  const { auth } = useAuthContext();
  const [isValid, setIsValid] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const subscribeToEventMutation = useMutation(subscribeToEvent, {
    onSuccess: () => {
      handleSuccess();
      onClose();
    },
  });

  const validateCodeMutation = useMutation(validateEventInviteCode, {
    onError: (error: any) => {
      setIsValid(false);
      if (error?.response?.data?.error?.name === 'PolicyError') {
        formik.setErrors({
          code: error?.response?.data?.error?.message,
        });
      }
    },
    onSuccess: () => {
      setIsValid(true);
    },
  });

  const onClose = () => {
    formik.resetForm();
    setIsValid(false);
    handleClose();
  };

  const validateCode = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = e.target;
    if (value) {
      validateCodeMutation.mutate({
        id: event.id,
        code: value?.toUpperCase(),
      });
    }
  };

  const ValidateForm = Yup.object().shape({
    code: Yup.string().required('Campo obrigatório'),
    participantName: Yup.string().when([], {
      is: () => auth.user?.type === 'legal_person',
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().nullable(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      code: '',
      participantName: '',
    },
    validationSchema: ValidateForm,
    validateOnBlur: true,
    onSubmit: (values) => {
      subscribeToEventMutation.mutate({
        id: event.id,
        code: values.code?.toUpperCase(),
        participantName: values.participantName,
        acceptedTermsAndConditions,
      });
    },
  });

  const validateCodeDebounce = useCallback(debounce(validateCode, 1000), []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: '980px',
          minHeight: '350px',
        },
      }}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton
          aria-label="fechar"
          color="inherit"
          size="small"
          onClick={onClose}>
          <Close fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({
          padding: '16px 74px',
          [theme.breakpoints.down('sm')]: {
            padding: '16px 24px',
          },
        })}>
        <form onSubmit={formik.handleSubmit}>
          <Typography fontWeight={700} fontSize={18}>
            Insira o seu código de acesso para este evento
          </Typography>
          <Grid
            container
            sx={(theme) => ({
              padding: '32px 16px',
              [theme.breakpoints.down('md')]: {
                rowGap: 4,
              },
              [theme.breakpoints.down('sm')]: {
                padding: '32px 0',
                rowGap: 4,
              },
            })}>
            <Grid
              item
              container
              md={6}
              xs={12}
              sx={(theme) => ({
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 4,
                paddingRight: '24px',
                [theme.breakpoints.down('md')]: {
                  paddingRight: 0,
                },
              })}>
              <Box width="100%">
                <FieldTitle>Código de convite</FieldTitle>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    name="code"
                    placeholder="Insira o código"
                    onChange={(event) => {
                      formik.handleChange(event);
                      validateCodeDebounce(event);
                    }}
                    inputProps={{ maxLength: 5 }}
                    value={formik.values.code?.toUpperCase()}
                    error={Boolean(formik.errors.code)}
                    helperText={formik.errors.code}
                  />
                </FormControl>
              </Box>
              {auth?.user?.type === 'legal_person' && (
                <Box width={'100%'}>
                  <FieldTitle>Nome completo</FieldTitle>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      name="participantName"
                      placeholder="Informe o nome de quem vai comparecer ao evento"
                      onChange={(event) => {
                        formik.handleChange(event);
                      }}
                      inputProps={{ maxLength: 50 }}
                      value={formik.values.participantName}
                      error={Boolean(formik.errors.participantName)}
                      helperText={formik.errors.participantName}
                    />
                  </FormControl>
                </Box>
              )}
              <CustomTooltip
                title="Insira um código válido para se inscrever"
                disableHoverListener={isValid}
                disableTouchListener={isValid}>
                <span>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!isValid}
                    sx={{
                      '&:disabled': {
                        backgroundColor: '#6D6E71',
                        color: 'white',
                      },
                    }}>
                    {subscribeToEventMutation.isLoading ||
                    validateCodeMutation.isLoading ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      'Inscrever-se'
                    )}
                  </Button>
                </span>
              </CustomTooltip>
            </Grid>
            <Grid
              item
              container
              md={6}
              xs={12}
              sx={(theme) => ({
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 4,
                padding: '8px 0 0 64px',
                [theme.breakpoints.down('md')]: {
                  padding: 0,
                },
              })}>
              <Typography textAlign="center" fontSize={12}>
                Caso possua um código de convite você pode inserir
                {isMobile ? ' acima ' : ' ao lado '}
                para se inscrever como convidado. Ou adquira o(s) plano(s)
                <strong> {buildNamesInline(event.plans)} </strong>e garanta seu
                ingresso.
              </Typography>
              <Button
                variant="outlined"
                size="medium"
                color="secondary"
                onClick={() => window.open('/planos')}>
                Planos
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
