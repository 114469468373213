import { createTheme } from '@mui/material';
import '@mui/material/styles';
import { ptBR as corePtBR } from '@mui/material/locale';
import { ptBR } from '@mui/x-date-pickers/locales';

const themev5 = createTheme(
  {
    palette: {
      mode: 'light',
      primary: {
        main: '#FF8211',
        dark: '#FC7F12',
        light: '#FDA85E',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: '#1D1D1D',
      },
      success: {
        main: '#27AE60',
        light: '#D3FFE6',
      },
      error: {
        main: '#EB5757',
        light: '#F4C7C7',
      },
      warning: {
        main: '#E2B93B',
      },
    },
    typography: {
      fontFamily: 'Inter',
      h1: {
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '56px',
        lineHeight: '62px',
      },
      h2: {
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '48px',
        lineHeight: '53px',
      },
      h3: {
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '40px',
        lineHeight: '44px',
      },
      h4: {
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '32px',
        lineHeight: '35px',
      },
      h5: {
        fontWeight: '700',
        fontSize: '24px',
        lineHeight: '26px',
      },
      h6: {
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '22px',
      },
      body2: {
        fontSize: '14px',
        fontWeight: '400px',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          text: {
            textTransform: 'uppercase',
            letterSpacing: '0.045em',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
          root: {
            textTransform: 'none',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
            borderRadius: '3px',
            fontWeight: 600,
          },
          sizeLarge: {
            minWidth: 228,
            height: 46,
            fontSize: '18px',
          },
          sizeMedium: {
            minWidth: 150,
            height: 40,
            fontSize: '12px',
            lineHeight: 1,
          },
          sizeSmall: {
            minWidth: 120,
            height: 34,
            fontSize: '12px',
            lineHeight: 1,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundColor: '#FFFFFF',
            borderRadius: '4px',
          },
          paperWidthMd: {
            maxWidth: 641,
            height: 555,
            maxHeight: 555,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            fill: '#CBCBCB',
          },
          iconOpen: {
            fill: '#E48125',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          colorInfo: {
            backgroundColor: '#BDBDBD',
          },
          sizeSmall: {
            fontSize: 'x-small',
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            color: '#000000',
          },
          text: {
            fontSize: '0.833rem',
            lineHeight: '22.4px',
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          label: {
            color: '#1D1D1D',
            fontSize: '14px',
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          line: {
            borderColor: '#000000',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: '2px',
          },
        },
      },
      MuiBottomNavigationAction: {
        styleOverrides: {
          root: {
            minWidth: 0,
            color: '#4F4F4F',
            marginRight: 62.5,
            padding: '6px 12px',
            '&.Mui-selected': {
              '&::after': {
                content: '" "',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                background: '#FF8211',
                height: 5,
                borderRadius: '20px 20px 0 0',
              },
              color: '#FF8211',
            },
            '&.Mui-disabled': {
              filter: 'opacity(0.4)',
            },
          },
          label: {
            fontSize: 16,
            fontWeight: 400,
            '&.Mui-selected': {
              fontSize: 16,
              fontWeight: 500,
            },
          },
        },
      },
    },
  },
  ptBR,
  corePtBR,
);

export default themev5;
