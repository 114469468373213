import { CircularProgress } from '@mui/material';
import { TabTemplateMobile } from 'src/components/UI/TabTemplateMobile';

import Header from './Header';
import { useEventTabManager } from './useEventTabManager';

export default function Event() {
  const {
    tabData,
    isLoading,
    isMobile,
    data,
    tabIndex,
    refetch,
    handleChange,
  } = useEventTabManager();

  if (isLoading) {
    return <CircularProgress size="1.8rem" color="secondary" />;
  }

  if (isMobile)
    return (
      <TabTemplateMobile
        eventData={data}
        tabIndex={tabIndex}
        menuItems={tabData}
        handleChange={handleChange}
        refetch={refetch}
      />
    );

  return (
    <Header
      data={data}
      refetch={refetch}
      handleChange={handleChange}
      tabIndex={tabIndex}
      tabData={tabData}
    />
  );
}
