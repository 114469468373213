import { useEffect, useState } from 'react';

import { useMediaQuery } from '@material-ui/core';
import { AttachMoney, Launch, Place } from '@mui/icons-material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Theme,
  Typography,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { Event } from 'src/types/event';
import { getSmallestImageUrl } from 'src/utils/image';

import Detail from './Detail';
import SubscribeButton from './SubscribeButton';
import Sponsors from '../Sponsors';

export default function MainPage({
  data,
  refetch,
}: {
  data: Event;
  refetch: () => void;
}) {
  const { auth } = useAuthContext();
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState(
    Boolean(data?.subscribed),
  );
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs'),
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box
        sx={(theme) => ({
          padding: '28px',
          [theme.breakpoints.down('sm')]: {
            padding: '20px',
          },
        })}>
        <Grid container spacing={isMobile ? 0 : 4}>
          <Grid
            container
            item
            md={6}
            sx={{
              flexDirection: 'column',
              gap: 3,
            }}>
            <Detail icon={<CalendarTodayIcon />}>
              {data.schedules.map((schedule) => {
                return data.isPreview ? (
                  <Typography key={schedule.id}>
                    <span
                      style={{
                        color: '#828282',
                        textTransform: 'capitalize',
                      }}>
                      {format(parseISO(schedule.startDate), 'MMMM / yyyy', {
                        locale: ptBR,
                      })}
                    </span>
                  </Typography>
                ) : (
                  <Typography key={schedule.id}>
                    <span style={{ color: '#828282' }}>
                      {format(parseISO(schedule.startDate), 'dd MMM yyyy', {
                        locale: ptBR,
                      })}
                    </span>
                    &nbsp;
                    {`${format(parseISO(schedule.startDate), 'HH:mm', {
                      locale: ptBR,
                    })} às ${format(parseISO(schedule.endDate), 'HH:mm', {
                      locale: ptBR,
                    })}`}
                  </Typography>
                );
              })}
            </Detail>
            <Detail icon={<Place />}>
              <Typography sx={{ color: '#828282' }}>
                Evento {data?.format?.toLowerCase()}
              </Typography>
            </Detail>
            <Detail icon={<AttachMoney />}>
              <Typography sx={{ color: '#828282' }}>
                Evento {data?.type?.toLowerCase()}
              </Typography>
            </Detail>
            {data?.url && (
              <Detail icon={<Launch />}>
                <Link
                  href={data.url}
                  target="_blank"
                  sx={(theme) => ({
                    fontSize: '16px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    wordBreak: 'break-word',
                    [theme.breakpoints.down('sm')]: {
                      whiteSpace: 'normal',
                    },
                  })}>
                  {data.url}
                </Link>
              </Detail>
            )}
            {data.theme && (
              <>
                <Typography
                  sx={{
                    color: '#828282',
                    marginTop: '8px',
                  }}>
                  Tema do evento
                </Typography>
                <Typography fontSize={18}>{data.theme}</Typography>
              </>
            )}
            {data.institution && (
              <>
                <Typography
                  sx={{
                    color: '#828282',
                  }}>
                  Organização responsável
                </Typography>
                <Link
                  href={`/instituicao/${data.institution.slug}/${data.institution.id}`}
                  underline="none"
                  target="_blank"
                  sx={(theme) => ({
                    fontSize: '18px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  })}>
                  {data.institution.fantasyName}
                  {data.institution.logo && (
                    <Avatar
                      variant="circular"
                      sx={{
                        width: '20px',
                        height: '20px',
                        '& > img': {
                          objectFit: 'contain',
                        },
                      }}
                      title={data.institution?.fantasyName}
                      src={getSmallestImageUrl(data.institution.logo)}
                    />
                  )}
                </Link>
              </>
            )}
            {data?.termsOfUseLink && auth.isLoggedIn && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptedTermsAndConditions}
                    onChange={(e) =>
                      setAcceptedTermsAndConditions(e.target.checked)
                    }
                    disabled={data.subscribed || !auth.isLoggedIn}
                  />
                }
                label={
                  <Detail icon={<Link />}>
                    <Typography>
                      Ao me inscrever no evento, eu concordo com os{' '}
                      <Link href={data.termsOfUseLink} target="_blank">
                        Termos e Condições
                      </Link>
                    </Typography>
                  </Detail>
                }
              />
            )}

            <Box position={'relative'}>
              <SubscribeButton
                event={data}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                refetch={refetch}
                typePosition="body"
              />
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            mt={isMobile ? 4 : 0}
            container
            sx={{
              flexDirection: 'column',
              gap: 3,
            }}>
            {data?.isUqbar &&
              data.subscriptionStartDate &&
              data.subscriptionEndDate && (
                <>
                  <Typography
                    sx={{
                      color: '#828282',
                    }}>
                    Inscrições
                  </Typography>
                  <Typography fontSize={18}>
                    {`${format(
                      parseISO(data.subscriptionStartDate),
                      'dd MMMM yyyy',
                      {
                        locale: ptBR,
                      },
                    )} a ${format(
                      parseISO(data.subscriptionEndDate),
                      'dd MMMM yyyy',
                      {
                        locale: ptBR,
                      },
                    )}`}
                  </Typography>
                </>
              )}
            <Typography
              sx={{
                color: '#828282',
                display: data.description ? 'block' : 'none',
              }}>
              Descrição do evento
            </Typography>
            <Typography
              sx={(theme) => ({
                whiteSpace: 'pre-line',
                fontSize: '18px',
                textAlign: 'justify',
                color: '#4F4F4F',
                [theme.breakpoints.down('sm')]: {
                  fontSize: '16px',
                },
              })}
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Sponsors mode="preview" />
    </>
  );
}
