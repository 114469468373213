import { useEffect, useRef, useState } from 'react';

import { ExpandMore, Share } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Button,
  CircularProgress,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { format, parse, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { CustomTooltip } from 'src/components/CustomTooltip';
import { FavoriteButton } from 'src/components/FavoriteButton';
import SubscribeToEventDialog from 'src/components/SubscribeToEventDialog';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { subscribeToEvent } from 'src/services/events';
import { Event, Presentation, Speaker as SpeakerType } from 'src/types/event';
import { Market } from 'src/types/market';
import { useQueryParams } from 'src/utils/hooks';
import { getSmallestImageUrl } from 'src/utils/image';

import SubscribeToEventParticipantNameDialog from '../../../../../components/SubscribeToEventParticipantNameDialog';
import { CustomShareButton } from '../ShareButton';
import SpeakersDialog from '../SpeakersDialog';

export default function PresentationAccordion({
  presentation,
  event,
  refetch,
  handleClickDate,
}: {
  presentation: Presentation;
  event: Event;
  refetch: () => void;
  handleClickDate: (value: string) => void;
}) {
  const [{ query }, setQuery] = useQueryParams<{
    presentationId?: string;
  }>({
    presentationId: '',
  });
  const { id, slug } = useParams<{ id: string; slug: string }>();
  const { auth } = useAuthContext();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const [isOpen, setIsOpen] = useState(
    Number(query?.presentationId) === presentation.id,
  );
  const [openSubscribeDialog, setSubscribeOpenDialog] = useState(false);
  const [openSpeakersDialog, setOpenSpeakersDialog] = useState(false);
  const accordionRef = useRef<null | HTMLInputElement>(null);

  const subscribeToEventMutation = useMutation(subscribeToEvent, {
    onSuccess: () => {
      openPresentationUrl();
      refetch();
    },
  });

  const getPresentationStateMessage = () => {
    switch (presentation.state) {
      case 'upcoming':
        return presentation.format;
      case 'ongoing':
        return 'Em andamento';
      case 'ended':
        return 'Palestra encerrada';
      default:
        return '';
    }
  };

  const openPresentationUrl = () => {
    window.open(
      `/evento/${slug}/${id}/palestra/${presentation.id}/live`,
      '_blank',
    );
  };

  const handleClick = (presentation: Presentation) => {
    if (event.subscribed) {
      openPresentationUrl();
      return;
    }

    if (!event.allowSubscriptions) return;
    if (event.hasPermission && auth.user?.type === 'private_person') {
      subscribeToEventMutation.mutate({ id: event.id });
    } else {
      setSubscribeOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setSubscribeOpenDialog(false);
  };

  const handleSubscribeSuccess = () => {
    openPresentationUrl();
    refetch();
  };

  useEffect(() => {
    if (isOpen && Number(query?.presentationId) === presentation.id) {
      window.scrollTo({
        top: (accordionRef?.current?.offsetTop ?? 0) - 100,
        behavior: 'smooth',
      });
      setQuery({ presentationId: '' }, 'remove');
    }
  }, [isOpen]);

  return (
    <Accordion
      expanded={isOpen}
      ref={accordionRef}
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
          '& .MuiAccordionSummary-expandIconWrapper': {
            position: 'absolute',
            right: '16px',
            bottom: '22px',
          },
        },
      })}>
      <AccordionSummary
        sx={(theme) => ({
          padding: '20px',
          pointerEvents: 'none',
          '& .MuiAccordionSummary-content': {
            margin: 0,
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: 0,
          },
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'none',
          },
          [theme.breakpoints.down('sm')]: {
            padding: '12px',
          },
        })}
        expandIcon={
          <Box
            display="flex"
            onClick={() => setIsOpen((prevState) => !prevState)}
            sx={{
              pointerEvents: 'auto',
            }}>
            <Typography color="#FF8211" fontSize={16} fontWeight={700}>
              Detalhes
            </Typography>
            <ExpandMore
              sx={{
                color: '#FF8211',
                transform: isOpen ? 'rotate(180deg)' : 'none',
              }}
            />
          </Box>
        }>
        <Grid
          container
          sx={(theme) => ({
            pointerEvents: 'auto',
            cursor: 'default',
            [theme.breakpoints.down('md')]: {
              rowGap: 2,
            },
            [theme.breakpoints.down('sm')]: {
              rowGap: 4,
            },
          })}>
          {presentation.state === 'ongoing' &&
          presentation.liveUrl &&
          (event.subscribed || event.allowSubscriptions) ? (
            <Grid
              item
              container
              md={2}
              sm={4}
              xs={6}
              sx={(theme) => ({
                alignItems: 'center',
                [theme.breakpoints.down('sm')]: {
                  padding: '8px',
                  minHeight: '60px',
                },
              })}>
              <CustomTooltip
                disableFocusListener
                disableTouchListener={auth.isLoggedIn}
                disableHoverListener={auth.isLoggedIn}
                arrow
                title={
                  !auth.isLoggedIn
                    ? 'Faça login para acessar esta funcionalidade'
                    : ''
                }
                placement="top">
                <span style={{ height: '100%' }}>
                  <Button
                    variant="contained"
                    sx={(theme) => ({
                      fontSize: '18px',
                      fontWeight: 600,
                      height: '100%',
                      borderRadius: '5px',
                      [theme.breakpoints.down('sm')]: {
                        fontSize: '14px',
                      },
                    })}
                    disabled={!auth.isLoggedIn && event.isUqbar}
                    onClick={() => handleClick(presentation)}
                    size={isMobile ? 'small' : 'medium'}>
                    {subscribeToEventMutation.isLoading ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      'Assistir agora'
                    )}
                  </Button>
                </span>
              </CustomTooltip>
            </Grid>
          ) : (
            <Grid
              item
              container
              md={2}
              sm={4}
              xs={6}
              sx={(theme) => ({
                alignItems: 'center',
                gap: 2,
                flexWrap: 'nowrap',
                '& button': {
                  '& > svg': {
                    color:
                      presentation.state === 'ended' ? '#FC5555' : '#FF8211',
                  },
                },
                [theme.breakpoints.down('sm')]: {
                  gap: 1,
                },
              })}>
              <FavoriteButton
                type="presentation"
                id={presentation.id}
                icon="bookmark"
                iconHeight="22.67px"
                iconWidth="unset"
                tooltipPlacement="top"
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  cursor: 'pointer',
                  textWrap: 'nowrap',
                }}
                onClick={() => handleClickDate(presentation.date)}>
                <Typography
                  color={presentation.state === 'ended' ? '#FC5555' : '#FF8211'}
                  sx={(theme) => ({
                    fontSize: '16px',
                    fontWeight: 700,
                    [theme.breakpoints.down('sm')]: {
                      fontSize: '14px',
                    },
                  })}>
                  {`${format(parseISO(presentation.date), 'dd MMMM', {
                    locale: ptBR,
                  })}`}
                </Typography>
                <Typography fontSize={14} color="#4F4F4F">
                  {`${format(
                    parse(presentation.startTime, 'HH:mm:ss', new Date()),
                    'HH:mm',
                  )}
                   - ${format(
                     parse(presentation.endTime, 'HH:mm:ss', new Date()),
                     'HH:mm',
                   )}`}
                </Typography>
              </Box>
            </Grid>
          )}
          <Grid
            item
            md={6}
            sm={8}
            xs={6}
            sx={(theme) => ({
              display: 'flex',
              paddingLeft: '16px',
              flexDirection: 'column',
              justifyContent: 'center',
              [theme.breakpoints.down('md')]: {
                padding: 0,
              },
            })}>
            <Typography
              sx={(theme) => ({
                fontWeight: 600,
                fontSize: '18px',
                [theme.breakpoints.down('sm')]: {
                  fontSize: '14px',
                },
              })}>
              {presentation.name}
            </Typography>
            <Typography
              sx={(theme) => ({
                fontSize: '14px',
              })}>
              {getPresentationStateMessage()}
              <Badge
                color="success"
                variant="dot"
                invisible={presentation.state !== 'ongoing'}
                sx={{
                  marginLeft: '8px',
                }}
              />
            </Typography>
          </Grid>
          <Grid
            item
            container
            md={4}
            sx={(theme) => ({
              paddingRight: '40px',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 1,
              [theme.breakpoints.down('md')]: {
                flexDirection: 'row-reverse',
                padding: 0,
              },
            })}>
            <AvatarGroup
              max={isMobile ? 2 : 4}
              sx={(theme) => ({
                cursor: 'pointer',
                '& .MuiAvatar-root': {
                  width: '50px',
                  height: '50px',
                },
                '& > div:nth-of-type(1)': {
                  backgroundColor: '#FFA14D',
                  fontSize: '16px',
                  fontWeight: 700,
                },
                [theme.breakpoints.down('sm')]: {
                  '& .MuiAvatar-root': {
                    width: '42px',
                    height: '42px',
                  },
                },
              })}
              onClick={() => setOpenSpeakersDialog(true)}>
              {presentation?.speakers?.map((speaker: SpeakerType) => (
                <Avatar
                  key={`${speaker.id}-speaker`}
                  alt={speaker?.professional?.fullName}
                  src={getSmallestImageUrl(speaker?.professional?.photo)}
                  imgProps={{
                    sx: {
                      objectFit: 'contain',
                    },
                  }}
                />
              ))}
            </AvatarGroup>
            <CustomShareButton data={presentation} Icon={Share} />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          backgroundColor: '#F1F1F1',
          padding: '32px',
          '& > p': {
            color: '#4F4F4F',
            fontSize: '18px',
            textAlign: 'justify',
          },
        })}>
        <Box
          sx={{
            display: 'flex',
            justifyContent:
              presentation?.markets?.length && !isMobile
                ? 'space-between'
                : 'flex-end',
          }}>
          {presentation?.markets?.length && !isMobile ? (
            <Typography>
              <strong>Mercado: </strong>
              {presentation.markets
                ?.map((market: Market) => market.name)
                .join(', ')}
            </Typography>
          ) : null}
          <Typography>
            <span style={{ color: '#828282' }}>
              {format(parseISO(presentation.date), 'dd MMM yyyy', {
                locale: ptBR,
              })}
            </span>
            &nbsp;
            {`${format(
              parse(presentation.startTime, 'HH:mm:ss', new Date()),
              'HH:mm',
              {
                locale: ptBR,
              },
            )} às ${format(
              parse(presentation.endTime, 'HH:mm:ss', new Date()),
              'HH:mm',
              {
                locale: ptBR,
              },
            )}`}
          </Typography>
        </Box>
        <Typography
          sx={{
            whiteSpace: 'pre-line',
          }}
          dangerouslySetInnerHTML={{
            __html: presentation.description,
          }}
        />
        {presentation?.speakers?.length ? (
          <Typography
            sx={(theme) => ({
              textTransform: 'capitalize',
              [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'column',
              },
            })}>
            <strong>Palestrantes: </strong>
            {isMobile
              ? presentation.speakers?.map((speaker: SpeakerType) => (
                  <Typography key={speaker.professional.id}>
                    {speaker.professional.fullName.toLowerCase()}
                  </Typography>
                ))
              : presentation.speakers
                  ?.map((speaker: SpeakerType) =>
                    speaker.professional.fullName.toLowerCase(),
                  )
                  .join(', ')}
          </Typography>
        ) : null}
        {presentation?.markets?.length && isMobile ? (
          <Typography>
            <strong>Mercado: </strong>
            {presentation.markets
              ?.map((market: Market) => market.name)
              .join(', ')}
          </Typography>
        ) : null}
        {event.hasPermission && auth?.user?.type === 'legal_person' ? (
          <SubscribeToEventParticipantNameDialog
            isOpen={openSubscribeDialog}
            handleClose={handleCloseDialog}
            event={event}
            handleSuccess={handleSubscribeSuccess}
          />
        ) : (
          <SubscribeToEventDialog
            isOpen={openSubscribeDialog}
            handleClose={handleCloseDialog}
            event={event}
            handleSuccess={handleSubscribeSuccess}
          />
        )}
        <SpeakersDialog
          speakers={presentation.speakers}
          open={openSpeakersDialog}
          handleClose={() => setOpenSpeakersDialog(false)}
        />
      </AccordionDetails>
    </Accordion>
  );
}
