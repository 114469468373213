import qs from 'qs';

import { api, publicApi } from '../../api/api';

export const getSecondaryFilters = async (hash: string) => {
  const { data } = await api.get(`/getSecondaryFilters?operationId=${hash}`);
  return data;
};

export const filterSecondary = async (values: any) => {
  const { data } = await api.post('/filterSecondary', values);
  return data;
};

export const exportSecondary = async (values: any) => {
  const { data } = await api.post('/filterSecondary/export', values);
  return data;
};

export const countOperations = async ({ market }: { market: string }) => {
  const query = qs.stringify(
    {
      market,
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await publicApi.get(`/operations-detailed/count?${query}`);
  return data;
};

export const findOperationsByName = async (name: string) => {
  const query = qs.stringify(
    {
      sort: ['name:asc'],
      filters: {
        name: {
          $containsi: name,
        },
      },
      populate: ['market'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get(`/operacoes?${query}`);
  return data;
};

export const getOperationRelatedArticles = async ({
  start,
  limit,
  operationId,
}: {
  start: number;
  limit: number;
  operationId: string;
}) => {
  const query = qs.stringify(
    {
      pagination: {
        start,
        limit,
      },
      filters: {
        relatedOperations: {
          hashId: operationId,
        },
        publishedAt: {
          $null: false,
        },
      },
      populate: ['headerImage', 'markets'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await publicApi.get(`/artigos?${query}`);
  return data;
};

export const getOperationForHome = async () => {
  const query = qs.stringify(
    {
      fields: ['name', 'hashId', 'slug'],
      populate: ['market'],
      pagination: {
        page: 1,
        pageSize: 6,
      },
      sort: ['createdAt:desc'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get(`/operacoes?${query}`);
  return data;
};

export const getColumnsBasicView = async ({ market }: { market: string }) => {
  const query = qs.stringify(
    {
      market,
    },
    {
      encodeValuesOnly: true,
    },
  );

  return api.get(`/operations-detailed/columns?${query}`);
};

export const getColumns = async ({
  market,
  indicator,
}: {
  market: string;
  indicator: string;
}) => {
  const query = qs.stringify(
    {
      market,
      indicator,
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get(`/meta/columns?${query}`);
  return data;
};

export const getAll = async (
  {
    market,
    indicator,
    cnpj,
    permission,
    mode,
  }: {
    market?: string;
    indicator?: string;
    cnpj?: string;
    permission?: string;
    mode: 'meta' | 'operation';
  },
  setProgress?: (value: number) => void,
) => {
  const query = qs.stringify(
    {
      market: mode === 'meta' ? market : undefined,
      indicator,
      permission,
      ...(cnpj
        ? {
            parameters: {
              filters: {
                cnpjFundoCota: cnpj,
              },
            },
          }
        : undefined),
      ...(mode === 'operation'
        ? {
            parameters: {
              filters: {
                market,
              },
            },
          }
        : undefined),
    },
    {
      encodeValuesOnly: true,
    },
  );
  const config = {
    onDownloadProgress: (progressEvent: any) => {
      if (setProgress) {
        const percentage = Math.round(
          (progressEvent.loaded * 100) /
            (progressEvent.total ??
              progressEvent.srcElement.getResponseHeader('content-length')),
        );
        setProgress(percentage);
      }
    },
  };
  const { data } = await api.get(`/meta/all?${query}`, config);
  return data;
};

export const getFIIWalletCRICRA = async ({ cnpj }: { cnpj: string }) => {
  const query = qs.stringify(
    {
      filters: {
        cnpjFundo: cnpj,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get(`/meta-fii-carteira-cri-cras?${query}`);
  return data;
};

export const getFIIWalletFII = async ({ cnpj }: { cnpj: string }) => {
  const query = qs.stringify(
    {
      filters: {
        cnpjFundo: cnpj,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get(`/meta-fii-carteira-fiis?${query}`);
  return data;
};

export const getFIIWalletFIDC = async ({ cnpj }: { cnpj: string }) => {
  const query = qs.stringify(
    {
      filters: {
        cnpjFundo: cnpj,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get(`/meta-fii-carteira-fidcs?${query}`);
  return data;
};

export const getFIIWalletProperty = async (params: any) => {
  const query = qs.stringify(
    {
      market: params.mode === 'meta' ? params.market : undefined,
      indicator: params.indicator,
      permission: params.permission,
      parameters: {
        filters: {
          cnpjFundo: params.cnpj,
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/meta-fii-ativo-imovels?${query}`);
  return data;
};

export const getCriCraInvestorsPositions = async ({
  operationId,
  permission,
}: {
  operationId: string;
  permission: string;
}) => {
  const query = qs.stringify(
    {
      permission,
      filters: {
        relatedOperation: {
          operationId,
        },
      },
      populate: {
        investor: {
          fields: ['name', 'slug', 'operationId'],
        },
        relatedOperation: {
          fields: ['class'],
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/cri-cra-investors-positions?${query}`);
  return data;
};

export const getCRIReceivablesDue = async ({
  operationId,
}: {
  operationId: string;
}) => {
  const query = qs.stringify(
    {
      filters: {
        operation: {
          operationId,
        },
      },
      sort: ['dataReferencia:desc'],
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get(`/meta-cri-recebiveis-a-vencers?${query}`);
  return data;
};

export const getCRAReceivablesDue = async ({
  operationId,
}: {
  operationId: string;
}) => {
  const query = qs.stringify(
    {
      filters: {
        operation: {
          operationId,
        },
      },
      sort: ['dataReferencia:desc'],
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get(`/meta-cra-recebiveis-a-vencers?${query}`);
  return data;
};

export const getOperationsFilters = async ({
  market,
  type,
  indicator,
}: {
  market: string;
  type: string;
  indicator?: string;
}) => {
  const query = qs.stringify(
    {
      filters: {
        market: {
          name: market,
        },
        type,
        indicator,
      },
      sort: ['createdAt:desc'],
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/operations-filters-by-user?${query}`);
  return data;
};

export const createOperationFilter = async (values: any) => {
  const { data } = await api.post('/operations-filters', values);
  return data;
};

export const deleteOperationsFilter = async (id: number) => {
  const {
    data: { data },
  } = await api.delete(`/operations-filters/${id}`);
  return data;
};
