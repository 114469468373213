import qs from 'qs';
import { StrapiResponse } from 'src/types/http';
import { Image } from 'src/types/institution';

import { api } from '../../api/api';

export const getVideos = async ({
  institutionId,
  page,
}: {
  institutionId: number;
  page: number;
}) => {
  const query = qs.stringify(
    {
      pagination: {
        page,
        pageSize: 12,
      },
      filters: {
        institution: institutionId,
      },
      populate: ['thumbnail'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get(`/gallery-videos?${query}`);
  return data;
};

export const createVideo = async (formData: any) => {
  const { data } = await api.post('/gallery-videos', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  return data;
};

export const deleteVideo = async (id: number) => {
  const { data } = await api.delete(`/gallery-videos/${id}`);
  return data;
};

export const updateVideo = async ({
  id,
  values,
}: {
  id: number;
  values: any;
}) => {
  const { data } = await api.put(`/gallery-videos/${id}`, values);
  return data;
};

export const createImage = async (formData: any) => {
  const { data } = await api.post('/gallery-images', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  return data;
};

export const deleteImage = async (id: number) => {
  const { data } = await api.delete(`/gallery-images/${id}`);
  return data;
};

export const updateImage = async ({
  id,
  values,
}: {
  id: number;
  values: any;
}) => {
  const { data } = await api.put(`/gallery-images/${id}`, values);
  return data;
};

export const getImagesByInstitution = async ({
  institutionId,
  page,
}: {
  institutionId: number;
  page: number;
}) => {
  const query = qs.stringify(
    {
      pagination: {
        page,
        pageSize: 12,
      },
      filters: {
        institution: {
          id: institutionId,
        },
      },
      populate: ['image'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get<StrapiResponse<Image[]>>(
    `/gallery-images?${query}`,
  );
  return data;
};
